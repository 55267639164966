import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.NODE_ENV === "production" ? "https://mewbe.novaknowledge.com.my/api" : "https://admin.myewawasan.novaxapp.com/api";
    Vue.axios.defaults.apiEndPoint = "/api";
    //nfh-console.log("Vue.axios.defaults.baseURL: ", Vue.axios.defaults.baseURL);
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  /**
   * Set the default HTTP request headers
   */
  setHeaderByParam(token) {
    if (token) {
      Vue.axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
    }
    // Vue.axios.defaults.headers.common[
    //   "Access-Control-Allow-Origin"
    // ] = `*`;
  },

  setHeaderWithMultiPart() {
    Vue.axios.defaults.headers.post["Content-Type"] = "multipart/form-data";
  },

  setHeaderWithPDF() {
    Vue.axios.defaults.headers.post["Content-Type"] = "application/pdf";
  },

  setHeaderWithPNG() {
    Vue.axios.defaults.headers.post["Content-Type"] = "application/png";
  },

  async query(resource, params) {
    return Vue.axios.get(resource, params)
      .then((resp) => {
        //nfh-console.log('API query: ', resp);
        return resp;
      })
      .catch((err) => {
        // this.$router.push("/login");
        throw new Error(`[KT] ApiService ${err}`);
      });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  async get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`)
      .then((resp) => {
        //nfh-console.log('API get: ', resp);
        return resp;
      })
      .catch(err => {
        // this.$router.push("/login");
        throw new Error(`[KT] ApiService ${err}`,err);
      });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return Vue.axios.delete(resource).catch((err) => {
      // xxconsole.log(err);
      this.$router.push("/login");
      throw new Error(`[RWV] ApiService ${err}`);
    });
  },
};

export default ApiService;
