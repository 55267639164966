import ApiService from "../api.service";
import JwtService from "../jwt.service";

// action types
export const REDIRECTOR                = "redirector";
export const GET_APPLICANT_PRELOCK     = "getApplicantPreLock";
export const GET_APPLICANT_UNDOLOCK    = "getApplicantUndoLock";
export const GET_APPLICANT_CONFIRMLOCK = "getApplicantConfirmLock";
export const GET_APPLICANT_OF_TOKENID  = "getApplicantOfTokenID";

// mutation types
export const SET_TOKEN                 = "setToken";

const state = {
  //
};

const getters = {
  //
};

const actions = {
  async [REDIRECTOR](context, credentials) {
    // if (JwtService.getToken()) {
      var redirParam = new URLSearchParams(credentials).toString();
      if (credentials) {
        // return ApiService.query("/redirect?" + new URLSearchParams(credentials).toString());
        //nfh-console.log('REDIRECTOR: credential: ', credentials, );
        //nfh-console.log('REDIRECTOR: param: ', redirParam, );
        return ApiService.query(`/redirect?${redirParam}`);
      }
    // }
    // else {
    //   alert('Sila Log masuk semula.');
    // }
  },
  async [GET_APPLICANT_OF_TOKENID](context, credentials) {
    if (JwtService.getToken()) {
      if (credentials) {
        ApiService.setHeader();
        return ApiService.post("/redir/applicant/tid", credentials);
      }
    }
    else {
      alert('Sila Log masuk semula.');
    }
  },
  async [GET_APPLICANT_PRELOCK](context, credentials) {
    if (JwtService.getToken()) {
      if (credentials) {
        ApiService.setHeader();
        return ApiService.post("/redir/applicant/lock/pre", credentials);
      }
    }
    else {
      alert('Sila Log masuk semula.');
    }
  },
  async [GET_APPLICANT_UNDOLOCK](context, credentials) {
    if (JwtService.getToken()) {
      if (credentials) {
        ApiService.setHeader();
        return ApiService.post("/redir/applicant/lock/undo", credentials);
      }
    }
    else {
      alert('Sila Log masuk semula.');
    }
  },
  async [GET_APPLICANT_CONFIRMLOCK](context, credentials) {
    if (JwtService.getToken()) {
      if (credentials) {
        ApiService.setHeader();
        return ApiService.post("/redir/applicant/lock/confirm", credentials);
      }
    }
    else {
      alert('Sila Log masuk semula.');
    }
  },
}

const mutations = {
  [SET_TOKEN](state, token) {
    JwtService.saveToken(token);
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}