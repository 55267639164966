import ApiService from "../api.service";
import JwtService from "../jwt.service";
import {
  blankUser,
} from "@/core/services/helper.service";

// action types
export const GET_USER = "getUser";
export const GET_USER_BY_BRANCH = "getUserByBranch";
export const SAVE_FCM_TOKEN = "saveFCMToken";
export const CREATE_PASSWORD = "createPassword";
export const RESET_PASSWORD = "resetPassword";
export const UPDATE_USER_PROFILE = "updateUserProfile";

// mutation types
export const SET_USER = "setUser";
export const SET_USER_ROLES = "setUserRoles";
export const SET_USER_FIRST_CHAR_NAME = "setUserFistCharName";
export const SET_USER_BY_BRANCH = "setUserByBranch";

export const PURGE_ALL_USER = "purgeAllUser";

const state = {
  data: {
    user: {},
    userByBranch: [],
    roles: [],
  },
  isMember: false,
  userFirstCharName: "",
};

// Roles
// ============================
// ID  Name             Guard
// --  ---------------- -------
// 1   Admin            web
// 2   HQ               web
// 3   Sales Online     web
// 4   Branch Manager   web
// 5   Member           web
// 6   Guest            web
// 7   Approver         web
// ============================

const getters = {
  currentUser(state) {
    return state.data.user;
  },
  currentUserRoles(state) {
    return state.data.roles;
  },
  dashboardAllowed(state) {
    return !!Array(1, 2, 3, 4, 5, 7).find(_ => state?.data?.roles?.includes(_));
  },
  newAppAllowed(state) {
    return !!Array(5, 6).find(_ => state?.data?.roles?.includes(_));
    // return Array(5, 6).find(_ => state?.data?.roles[0]);
  },
  faqAllowed(state) {
    return !!Array(1, 2, 3, 4, 5, 6, 7).find(_ => state?.data?.roles?.includes(_));
  },
  helpAllowed(state) {
    return !!Array(1, 2, 3, 4, 5, 6, 7).find(_ => state?.data?.roles?.includes(_));
  },
  getUserFirstCharName(state) {
    return state.userFirstCharName;
  },
  userByBranch(state) {
    return state.userByBranch;
  },
  isMember(state) {
    return state.isMember;
  }
};

const actions = {
  async [GET_USER](context, credentials) {
    const apiRoute = "/user";
    var _ret = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      await ApiService.post(apiRoute, credentials)
        .then(async (response) => {
          const res = response?.data;

          if (res?.success) {
            //nfh-console.log(`[${apiRoute}] GET_USER <fetched>`, response);
            const data = JSON.parse(Buffer.from(res?.data, 'base64'));
            const theUser = data?.user;
            const theRole = data?.roles;

            await context.commit(SET_USER, theUser);
            await context.commit(SET_USER_ROLES, theRole);
            await context.commit(
              SET_USER_FIRST_CHAR_NAME,
              theUser?.short_name?.charAt(0)?.toUpperCase()
              // res?.data?.user?.short_name,
            );
            // return data?.user;
            _ret = data?.user;
          }
          else {
            //nfh-console.log(`[${apiRoute}] GET_USER <FAILED>`, response);
            await context.commit(SET_USER, blankUser());
            // return blankUser();
            _ret = blankUser();
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] GET_USER <CATCH-ERR>`, err);
        });

        if (!_ret) {
          //nfh-console.log(`[${apiRoute}] GET_USER <NULLED>`);
        }
    }
    else {
      //nfh-console.log(`[${apiRoute}] GET_USER <getToken FAILED>`);
    }

    return _ret;
  },
  async [GET_USER_BY_BRANCH](context, credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return await ApiService.post("/getUserByBranch");
    }
  },
  [SAVE_FCM_TOKEN](state, credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/_sys/cfg/fcmt", credentials);
    }
  },
  [CREATE_PASSWORD](state, credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/createPassword", credentials);
    }
  },
  [RESET_PASSWORD](state, credentials) {
    return ApiService.post('/resetPassword', credentials);
  },
  [UPDATE_USER_PROFILE](state, credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/user/update", credentials);
    }
  }
};

const mutations = {
  [SET_USER](state, user) {
    state.data.user = user;
    state.data.dt = user.nric;
    //nfh-console.log('[STATE.user.module] user <updated>', user);
  },

  [SET_USER_ROLES](state, roles) {
    state.data.roles = roles;
    //nfh-console.log('[STATE.user.module] roles <updated>', roles);
    // roles.forEach((role, i) => {
    //   // TODO
    //   // To reconfirm again role "Member" is same as isMember flag
    //   // if (role == "Member") {
    //   if (role == 5) {
    //     state.isMember = true;
    //   } else {
    //     state.isMember = false;
    //   }
    // });
  },

  [SET_USER_FIRST_CHAR_NAME](state, full_name) {
    state.userFirstCharName = full_name;
    //nfh-console.log('[STATE.user.module] full_name <updated>', full_name);
  },

  [SET_USER_BY_BRANCH](state, userByBranch) {
    state.userByBranch = userByBranch;
    //nfh-console.log('[STATE.user.module] userByBranch <updated>', userByBranch);
  },

  [PURGE_ALL_USER](state) {
    state.data = {
      user: {},
      userByBranch: [],
      roles: [],
    };
    state.isMember = false;
    state.userFirstCharName = "";
    //nfh-console.log('[STATE.user.module] PURGE_ALL_USER <PURGED>', state);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
