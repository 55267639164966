import axios from "axios";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import config from "@/core/services/store/config.module";
import {
  makeDataReadable,
} from "@/core/services/helper.service";

//==================================================================================================
// action types
//
export const GET_APPLICATIONS                      = "getApplications";
export const GET_APPLICATIONS_BY_PAGE              = "getApplicationByPage";
export const GET_APPS_ROLE_MEMBER                  = "getAppsRoleMember";

// Data list for Sales' dashboard tabs
export const GET_APPS_ROLE_SALES_ASSIST            = "getAppsRoleSalesAssistable";
export const GET_APPS_ROLE_SALES_PROCESS           = "getAppsRoleSalesProcess";
export const GET_APPS_ROLE_SALES_REMARK            = "getAppsRoleSalesRemarked";
export const GET_APPS_ROLE_SALES_APPRVD            = "getAppsRoleSalesApproved";

// Data list for Processing's dashboard tabs
export const GET_APPS_ROLE_PROC_RCVD               = "getAppsRoleProcRcvd";
export const GET_APPS_ROLE_PROC_TOAPPR             = "getAppsRoleProcToApprove";
export const GET_APPS_ROLE_PROC_REMARK             = "getAppsRoleProcRemarked";
export const GET_APPS_ROLE_PROC_APPRVD             = "getAppsRoleProcApproved";
export const GET_APPS_ROLE_PROC_REJECT             = "getAppsRoleProcReject";

export const GET_APPS_BY_URL                       = "getApplicationByUrl";

export const SHOW_APPLICATION                      = "showApplication";
export const CREATE_APPLICATION                    = "createApplication";
export const DELETE_APPLICATION                    = "deleteApplication";
export const GET_ASSIGNED_APPLICATIONS_BY_BRANCH   = "getAssignedApplicationsByBranch";
export const GET_UNASSIGNED_APPLICATIONS_BY_BRANCH = "getUnassignedApplicationsByBranch";
export const ASSIGN_APPLICATION                    = 'assignApplication';
export const ASSIGN_APPLICATION_BY_EMAIL           = 'assignApplicationByEmail';
export const UPDATE_APPLICATION_FINANCING          = 'updateApplicationFinancing';

export const IMPORT_APPLICATION_TO_STORE           = 'ImportApplicationToStore';

export const DELETE_ASSIGNED_APPLICATION           = 'deleteAssignedApplication';

// Checklists
export const GET_CHECKLIST_APP_FULL                = "getChecklistAppFull";

// Member, Employment, Heir
export const GET_MEMBER                            = "getMember";
export const GET_MEMBER_TO_STORE                   = "getMemberToStore";
export const GET_EMPLOYMENT                        = "getEmployment";
export const GET_EMPLOYMENT_TO_STORE               = "getEmploymentToStore";
export const GET_HEIR                              = "getHeir";
export const GET_HEIR_TO_STORE                     = "getHeirToStore";
export const CREATE_MEMBER                         = "createMember";
export const CREATE_EMPLOYMENT                     = "createEmployment";
export const CREATE_HEIR                           = "createHeir";

// For Customer Service (Sales Online)
export const GET_COMPLETE_APPLICATIONS             = 'getCompleteApplications';
export const GET_APPROVED_APPLICATIONS_SO          = 'getApprovedApplicationsSO';
export const GET_APPROVED_APPLICATIONS_CS          = 'getApprovedApplicationsCS';
export const COMPLETE_APPLICATION                  = 'completeApplication';

// For Processing
export const GET_REMARK_APPLICATIONS               = "getRemarkApplications"
export const PROCESS_TO_ILMS                       = "processToILMS";
export const REJECT_APPLICATION                    = "rejectApplication";

export const GET_APPROVED_APPLICATIONS             = "getApprovedApplications";

export const UPDATE_HEIR                           = "updateHeir";
export const UPDATE_MEMBER                         = "updateMember";
export const UPDATE_EMPLOYMENT                     = "updateEmployment";

//==================================================================================================
// mutation types
//
export const SET_HEIR                               = "setHeir";
export const SET_MEMBER                             = "setMember";
export const SET_EMPLOYMENT                         = "setEmployment";

export const SET_APPLICATION                        = "setApplication";
export const SET_APPLICATIONS                       = "setApplications";
export const SET_FINANCING_APP                      = "setFinancingApp";
export const SET_CHECKLIST_APP_FULL                 = "setChecklistAppFull";
export const SET_UNASSIGNED_APPLICATIONS            = "setUnassignedApplications";
export const SET_COMPLETE_APPLICATIONS              = 'setCompleteApplications';
export const SET_APPROVED_APPLICATIONS              = 'setApprovedApplications';
export const SET_RETURNED_APPLICATIONS              = 'setReturnedApplications';

export const SET_PAGED_APPS_ROLE_MEMBER             = "setPagedAppsRoleMember";

// Stores for Sales' dashboard tabs
export const SET_PAGED_APPS_ROLE_SALES_ASSIST       = "setPagedAppsRoleSalesAssistable";
export const SET_PAGED_APPS_ROLE_SALES_PROCESS      = "setPagedAppsRoleSalesProcess";
export const SET_PAGED_APPS_ROLE_SALES_REMARK       = "setPagedAppsRoleSalesRemarked";
export const SET_PAGED_APPS_ROLE_SALES_APPRVD       = "setPagedAppsRoleSalesApproved";

// Stores for Processing's dashboard tabs
export const SET_PAGED_APPS_ROLE_PROC_RCVD          = "setPagedAppsRoleProcRcvd";
export const SET_PAGED_APPS_ROLE_PROC_TOAPPR        = "setPagedAppsRoleProcToAppr";
export const SET_PAGED_APPS_ROLE_PROC_REMARK        = "setPagedAppsRoleProcRemark";
export const SET_PAGED_APPS_ROLE_PROC_APPRVD        = "setPagedAppsRoleProcApprvd";
export const SET_PAGED_APPS_ROLE_PROC_REJECT        = "setPagedAppsRoleProcReject";

export const SET_PAGINATE_APPLICATIONS              = "setPaginateApplications";
export const SET_PAGINATE_UNASSIGNED_APPLICATIONS   = "setPaginateUnassignedApplications";
export const SET_PAGINATE_REMARK_APPLICATION        = 'setPaginateRemarkApplications';

export const UNSET_APPLICATION                      = "unsetApplication";
export const UNSET_APPLICATIONS                     = "unsetApplications";
export const UNSET_CHECKLIST_APP_FULL               = "unsetChecklistAppFull";
export const UNSET_UNASSIGNED_APPLICATIONS          = "unsetUnassignedApplications";
export const UNSET_COMPLETE_APPLICATIONS            = 'unsetCompleteApplications';
export const UNSET_APPROVED_APPLICATIONS            = 'unsetApprovedApplications';
export const UNSET_RETURNED_APPLICATIONS            = 'unsetReturnedApplications';
export const UNSET_PAGED_APPS_ROLE_MEMBER           = "unsetPagedAppsRoleMember";
export const UNSET_PAGINATE_APPLICATIONS            = "unsetPaginateApplications";
export const UNSET_PG_ASSISTABLE_APPLICATIONS       = "unsetPaginatedAssistableApplications";
export const UNSET_PAGINATE_UNASSIGNED_APPLICATIONS = "unsetPaginateUnassignedApplications";
export const UNSET_PAGINATE_REMARK_APPLICATION      = 'unsetPaginateRemarkApplications';

export const UNSET_PAGED_APPS_ROLE_SALES_ASSIST     = "unsetPagedAppsRoleSalesAssistable";
export const UNSET_PAGED_APPS_ROLE_SALES_PROCESS    = "unsetPagedAppsRoleSalesProcess";
export const UNSET_PAGED_APPS_ROLE_SALES_REMARK     = "unsetPagedAppsRoleSalesRemarked";
export const UNSET_PAGED_APPS_ROLE_SALES_APPRVD     = "unsetPagedAppsRoleSalesApproved";

export const PURGE_APPLICATION_SPECIFIC             = "purgeApplicationSpecific";
export const PURGE_HEIR                             = "purgeHeir";
export const PURGE_MEMBER                           = "purgeMember";
export const PURGE_EMPLOYMENT                       = "purgeEmployment";
export const PURGE_ALL                              = "purgeAll";

//==================================================================================================
// state
//
const state = {
  heir: null,
  member: null,
  employment: null,
  application: null,
  applications: [],
  checklistAppFull: [],
  unassignedApplications: [],
  completeApplications: {},
  approvedApplications: {},
  returnedApplications: {},
  paginateApplications: {},

  assistableApplications: {},
  paginateRemarkApplications: {},
  paginateUnassignedApplications: {},

  pagedAppsRoleMember: {},

  pagedAppsRoleSalesAssistable: {},
  pagedAppsRoleSalesRemarked: {},
  pagedAppsRoleSalesProcess: {},
  pagedAppsRoleSalesApproved: {},

  pagedAppsRoleProcRcvd: {},
  pagedAppsRoleProcToAppr: {},
  pagedAppsRoleProcRemark: {},
  pagedAppsRoleProcApprvd: {},
  pagedAppsRoleProcReject: {},
};

//==================================================================================================
// getters
//
const getters = {
  heir(state) {
    return state.heir;
  },

  member(state) {
    return state.member;
  },

  employment(state) {
    return state.employment;
  },

  application(state) {
    return state.application;
  },

  applications(state) {
    return state.applications;
  },

  checklistAppFull(state) {
    return state.checklistAppFull;
  },

  unassignedApplications(state) {
    return state.unassignedApplications;
  },

  completeApplications(state) {
    return state.completeApplications;
  },

  approvedApplications(state) {
    return state.approvedApplications;
  },

  paginateApplications(state) {
    return state.paginateApplications;
  },

  returnedApplications(state) {
    return state.returnedApplications;
  },

  pagedAppsRoleSalesAssistable(state) {
    return state.pagedAppsRoleSalesAssistable;
  },

  pagedAppsRoleSalesRemarked(state) {
    return state.pagedAppsRoleSalesRemarked;
  },

  pagedAppsRoleSalesProcess(state) {
    return state.pagedAppsRoleSalesProcess;
  },

  pagedAppsRoleSalesApproved(state) {
    return state.pagedAppsRoleSalesApproved;
  },

  pagedAppsRoleMember(state) {
    return state.pagedAppsRoleMember;
  },

  pagedAppsRoleProcRcvd(state) {
    return state.pagedAppsRoleProcRcvd;
  },

  pagedAppsRoleProcToAppr(state) {
    return state.pagedAppsRoleProcToAppr;
  },

  pagedAppsRoleProcApprvd(state) {
    return state.pagedAppsRoleProcApprvd;
  },

  pagedAppsRoleProcReject(state) {
    return state.pagedAppsRoleProcReject;
  },

  pagedAppsRoleProcRemark(state) {
    return state.pagedAppsRoleProcRemark;
  },

  paginateRemarkApplications(state) {
    return state.paginateRemarkApplications;
  },

  paginateUnassignedApplications(state) {
    return state.paginateUnassignedApplications;
  },
};

//==================================================================================================
// actions
//
const actions = {
  async [GET_APPLICATIONS](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get("/apps");
    }
  },

  async [GET_APPS_ROLE_SALES_ASSIST](context) {
    const apiRoute = "/cs/list/assist";
    var   _ret     = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      // return ApiService.get("/apps");
      await ApiService.get(apiRoute)
        .then((resp) => {
          const _res = makeDataReadable(resp?.data);

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_SALES_ASSIST <fetched>`, _res);
            context.commit(SET_PAGED_APPS_ROLE_SALES_ASSIST, _res?.data);
          }
          else {
            //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_SALES_ASSIST <FAILED>`, resp);
          }

          _ret = _res;
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_SALES_ASSIST <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_SALES_ASSIST <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_SALES_ASSIST <getToken FAILED>`);
    }

    return _ret;
  },

  async [GET_APPS_ROLE_SALES_REMARK](context) {
    const apiRoute = "/cs/list/remark";
    var   _ret     = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      await ApiService.get(apiRoute)
        .then((resp) => {
          const _res = makeDataReadable(resp?.data);

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_SALES_REMARK <fetched>`, _res);
            context.commit(SET_PAGED_APPS_ROLE_SALES_REMARK, _res?.data);
          }
          else {
            //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_SALES_REMARK <FAILED>`, resp);
          }

          _ret = _res;
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_SALES_REMARK <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_SALES_REMARK <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_SALES_REMARK <getToken FAILED>`);
    }

    return _ret;
  },

  async [GET_APPS_ROLE_SALES_APPRVD](context) {
    const apiRoute = "/cs/list/apprvd";
    var   _ret     = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      // return ApiService.get("/apps");
      await ApiService.get(apiRoute)
        .then((resp) => {
          const _res = makeDataReadable(resp?.data);

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_SALES_APPRVD <fetched>`, _res);
            context.commit(SET_PAGED_APPS_ROLE_SALES_APPRVD, _res?.data);
          }
          else {
            //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_SALES_APPRVD <FAILED>`, resp);
          }

          _ret = _res;
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_SALES_APPRVD <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_SALES_APPRVD <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_SALES_APPRVD <getToken FAILED>`);
    }

    return _ret;
  },

  async [GET_APPS_ROLE_SALES_PROCESS](context) {
    const apiRoute = "/cs/list/process";
    var   _ret     = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      // return ApiService.get("/apps");
      await ApiService.get(apiRoute)
        .then((resp) => {
          const _res = makeDataReadable(resp?.data);

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_SALES_PROCESS <fetched>`, _res);
            context.commit(SET_PAGED_APPS_ROLE_SALES_PROCESS, _res?.data);
          }
          else {
            //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_SALES_PROCESS <FAILED>`, resp);
          }

          _ret = _res;
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_SALES_PROCESS <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_SALES_PROCESS <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_SALES_PROCESS <getToken FAILED>`);
    }

    return _ret;
  },

  async [GET_APPS_ROLE_MEMBER](context) {
    const apiRoute = "/apps/list/member";
    var   _ret     = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      await ApiService.get(apiRoute)
        .then((resp) => {
          const _res = makeDataReadable(resp?.data);

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_MEMBER <fetched>`, _res);
            context.commit(SET_PAGED_APPS_ROLE_MEMBER, _res?.data);
          }
          else {
            //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_MEMBER <FAILED>`, resp);
          }

          _ret = _res;
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_MEMBER <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_MEMBER <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_MEMBER <getToken FAILED>`);
    }

    return _ret;
  },

  async [GET_APPS_ROLE_PROC_RCVD](context) {
    // if (JwtService.getToken()) {
    //   ApiService.setHeader();
    //   return ApiService.get("/proc/list/rcvd");
    // }
    const apiRoute = "/proc/list/rcvd";
    var   _ret     = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      await ApiService.get(apiRoute)
        .then((resp) => {
          const _res = makeDataReadable(resp?.data);

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_RCVD <fetched>`, _res);
            context.commit(SET_PAGED_APPS_ROLE_PROC_RCVD, _res?.data);
          }
          else {
            //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_RCVD <FAILED>`, resp);
          }

          _ret = _res;
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_RCVD <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_RCVD <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_RCVD <getToken FAILED>`);
    }

    return _ret;
  },

  async [GET_APPS_ROLE_PROC_TOAPPR](context) {
    // if (JwtService.getToken()) {
    //   ApiService.setHeader();
    //   return ApiService.get("/proc/list/toappr");
    // }
    const apiRoute = "/proc/list/toappr";
    var   _ret     = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      await ApiService.get(apiRoute)
        .then((resp) => {
          const _res = makeDataReadable(resp?.data);

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_TOAPPR <fetched>`, _res);
            context.commit(SET_PAGED_APPS_ROLE_PROC_TOAPPR, _res?.data);
          }
          else {
            //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_TOAPPR <FAILED>`, resp);
          }

          _ret = _res;
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_TOAPPR <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_TOAPPR <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_TOAPPR <getToken FAILED>`);
    }

    return _ret;
  },

  async [GET_APPS_ROLE_PROC_APPRVD](context) {
    const apiRoute = "/proc/list/apprvd";
    var   _ret     = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      await ApiService.get(apiRoute)
        .then((resp) => {
          const _res = makeDataReadable(resp?.data);

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_APPRVD <fetched>`, _res);
            context.commit(SET_PAGED_APPS_ROLE_PROC_APPRVD, _res?.data);
          }
          else {
            //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_APPRVD <FAILED>`, resp);
          }

          _ret = _res;
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_APPRVD <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_APPRVD <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_APPRVD <getToken FAILED>`);
    }

    return _ret;
  },

  async [GET_APPS_ROLE_PROC_REJECT](context) {
    const apiRoute = "/proc/list/reject";
    var   _ret     = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      await ApiService.get(apiRoute)
        .then((resp) => {
          const _res = makeDataReadable(resp?.data);

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_REJECT <fetched>`, _res);
            context.commit(SET_PAGED_APPS_ROLE_PROC_REJECT, _res?.data);
          }
          else {
            //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_REJECT <FAILED>`, resp);
          }

          _ret = _res;
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_REJECT <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_REJECT <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_REJECT <getToken FAILED>`);
    }

    return _ret;
  },

  async [GET_APPS_ROLE_PROC_REMARK](context) {
    // if (JwtService.getToken()) {
    //   ApiService.setHeader();
    //   return ApiService.get("/proc/list/remark");
    // }
    const apiRoute = "/proc/list/remark";
    var   _ret     = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      await ApiService.get(apiRoute)
        .then((resp) => {
          const _res = makeDataReadable(resp?.data);

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_REMARK <fetched>`, _res);
            context.commit(SET_PAGED_APPS_ROLE_PROC_REMARK, _res?.data);
          }
          else {
            //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_REMARK <FAILED>`, resp);
          }

          _ret = _res;
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_REMARK <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_REMARK <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] GET_APPS_ROLE_PROC_REMARK <getToken FAILED>`);
    }

    return _ret;
  },

  async [GET_MEMBER](context, credentials) {
    if (JwtService.getToken()) {
      const apiRoute = `/apps/${credentials}/member/get`;
      var _ret = {
        success: false,
        message: "",
        data: {},
      };
      var _data;

      ApiService.setHeader();
      return await ApiService.post(apiRoute);
    }
  },

  async [GET_MEMBER_TO_STORE](context, credentials) {
    const apiRoute = `/apps/${credentials}/member/get`;

    if (JwtService.getToken()) {
      var _ret = {
        success: false,
        message: "",
        data: {},
      };

      ApiService.setHeader();

      await ApiService.get(apiRoute)
        .then((resp) => {
          const _res = makeDataReadable(resp?.data);

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] GET_MEMBER_TO_STORE <fetched>`, _res);
            context.commit(SET_MEMBER, _res?.data);
          }
          else {
            //nfh-console.log(`[${apiRoute}] GET_MEMBER_TO_STORE <FAILED>`, resp);
          }

          _ret = _res;
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] GET_MEMBER_TO_STORE <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] GET_MEMBER_TO_STORE <NULLED>`);
      }

      return _ret;
    }
  },

  async [GET_EMPLOYMENT](context, credentials) {
    const apiRoute = `/apps/${credentials}/employment/get`;

    if (JwtService.getToken()) {
      var _ret = {
        success: false,
        message: "",
        data: {},
      };

      ApiService.setHeader();

      await ApiService.post(apiRoute)
        .then((resp) => {
          const _res = makeDataReadable(resp?.data);

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] GET_EMPLOYMENT <fetched>`, _res);
            context.commit(SET_EMPLOYMENT, _res?.data);
          }
          else {
            //nfh-console.log(`[${apiRoute}] GET_EMPLOYMENT <FAILED>`, resp);
          }

          _ret = _res;
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] GET_EMPLOYMENT <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] GET_EMPLOYMENT <NULLED>`);
      }

      return _ret;
    }
  },

  async [GET_EMPLOYMENT_TO_STORE](context, credentials) {
    const apiRoute = `/apps/${credentials}/employment/get`;

    if (JwtService.getToken()) {
      var _ret = {
        success: false,
        message: "",
        data: {},
      };

      ApiService.setHeader();

      await ApiService.post(apiRoute)
        .then((resp) => {
          const _res = makeDataReadable(resp?.data);

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] GET_EMPLOYMENT_TO_STORE <fetched>`, _res);
            context.commit(SET_EMPLOYMENT, _res?.data);
            // let _readableResp = makeDataReadable(_res);
            // _data = _readableResp.data;
            // context.commit(SET_EMPLOYMENT, _data); //_res.data);
            // _ret = _readableResp; //_res;
          }
          else {
            //nfh-console.log(`[${apiRoute}] GET_EMPLOYMENT_TO_STORE <FAILED>`, resp);
          }

          _ret = _res;
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] GET_EMPLOYMENT_TO_STORE <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] GET_EMPLOYMENT_TO_STORE <NULLED>`);
      }

      return _ret;
    }
  },

  async [GET_HEIR](context, credentials) {
    const apiRoute = `/apps/${credentials}/heir/get`;

    if (JwtService.getToken()) {
      var _ret = {
        success: false,
        message: "",
        data: {},
      };

      ApiService.setHeader();
      // return await ApiService.post(apiRoute);

      await ApiService.post(apiRoute)
        .then((resp) => {
          const _res = makeDataReadable(resp?.data);

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] GET_HEIR <fetched>`, _res);
          }
          else {
            //nfh-console.log(`[${apiRoute}] GET_HEIR <FAILED>`, resp);
          }

          _ret = _res;
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] GET_HEIR <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] GET_HEIR <NULLED>`);
      }

      return _ret;
    }
  },

  async [GET_HEIR_TO_STORE](context, credentials) {
    const apiRoute = `/apps/${credentials}/heir/get`;

    if (JwtService.getToken()) {
      var _ret = {
        success: false,
        message: "",
        data: {},
      };

      ApiService.setHeader();

      await ApiService.get(apiRoute)
        .then((resp) => {
          const _res = makeDataReadable(resp?.data);

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] GET_HEIR_TO_STORE <fetched>`, _res);
            context.commit(SET_HEIR, _res?.data);
          }
          else {
            //nfh-console.log(`[${apiRoute}] GET_HEIR_TO_STORE <FAILED>`, resp);
          }

          _ret = _res;
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] GET_HEIR_TO_STORE <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] GET_HEIR_TO_STORE <NULLED>`);
      }

      return _ret;
    }
  },

  async [CREATE_MEMBER](context, credentials) {
    if (credentials) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        return await ApiService.post("/apps/member/create", credentials);
      }
    }
  },

  async [CREATE_EMPLOYMENT](context, credentials) {
    if (credentials) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        return await ApiService.post("/apps/employment/create", credentials);
      }
    }
  },

  async [CREATE_HEIR](context, credentials) {
    if (credentials) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        return await ApiService.post("/apps/heir/create", credentials);
      }
    }
  },

  async [IMPORT_APPLICATION_TO_STORE](context, credentials) {
    const apiRoute = `/apps/${credentials}`;
    var _ret       = null;
    var _data      = {};

    if (JwtService.getToken() && isFinite(credentials)) {
      ApiService.setHeader();

      await ApiService.get(apiRoute)
        .then((resp) => {
          const _res = makeDataReadable(resp?.data);
          _data      = _res?.data;

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] IMPORT_APPLICATION_TO_STORE <fetched>`, _res);
            // let _readableResp = makeDataReadable(_res);
            // console.log(`[${apiRoute}] IMPORT_APPLICATION_TO_STORE makeDataReadable`, _readableResp);
            context.commit(SET_APPLICATION, _data); //_res.data);
            context.commit(SET_HEIR, _data?.heir); //_res.data?.heir);
            context.commit(SET_MEMBER, _data?.member); //_res.data?.member);
            context.commit(SET_EMPLOYMENT, _data?.employment); //_res.data?.employment);
            // _ret = _res.data;
            _ret = _data;
          }
          else {
            _ret = _res;
            //nfh-console.log(`[${apiRoute}] IMPORT_APPLICATION_TO_STORE <FAILED>`, resp);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] IMPORT_APPLICATION_TO_STORE <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] IMPORT_APPLICATION_TO_STORE <NULLED>`);
      }

      return _ret;
    }
  },

  async [SHOW_APPLICATION](context, credentials) {
    const apiRoute = `/apps/${credentials}`;
    var _ret       = null;
    var _data      = {};

    if (JwtService.getToken() && isFinite(credentials)) {
      await ApiService.get(apiRoute)
        .then((resp) => {
          const _res = resp?.data;

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] SHOW_APPLICATION <fetched>`, _res);

            let _readableResp = makeDataReadable(_res);
            _data = _readableResp.data;

            //nfh-console.log(`[${apiRoute}] SHOW_APPLICATION makeDataReadable`, _readableResp);
            context.commit(SET_APPLICATION, _data);
            context.commit(SET_HEIR, _data?.heir);
            context.commit(SET_MEMBER, _data?.member);
            context.commit(SET_EMPLOYMENT, _data?.employment);
            _ret = _readableResp;
          }
          else {
            _ret = _res;
            //nfh-console.log(`[${apiRoute}] SHOW_APPLICATION <FAILED>`, resp);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] SHOW_APPLICATION <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] SHOW_APPLICATION <NULLED>`);
      }

      return _ret ?? {};
    }
  },

  async [CREATE_APPLICATION](context, credentials) {
    const apiRoute = `/apps`;
    var _ret       = null;

    if (JwtService.getToken()) {
      credentials.appid = (process.env.NODE_ENV === "production") ? config.state.appid_prod : config.state.appid_dev;

      ApiService.setHeader();

      // return ApiService.post("/apps", credentials);
      await ApiService.post(apiRoute, credentials)
        .then((resp) => {
          const _res = resp?.data;

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] CREATE_APPLICATION <fetched>`, _res);

            let _readableResp = makeDataReadable(_res);
            //nfh-console.log(`[${apiRoute}] CREATE_APPLICATION makeDataReadable`, _readableResp);
            _ret = _readableResp;
          }
          else {
            _ret = _res;
            //nfh-console.log(`[${apiRoute}] CREATE_APPLICATION <FAILED>`, resp);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] CREATE_APPLICATION <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] CREATE_APPLICATION <NULLED>`);
      }

      return _ret ?? {};
    }
  },

  async [UPDATE_APPLICATION_FINANCING](context, credentials) {
    var _context = {};

    if (JwtService.getToken()) {
      credentials.appid = (process.env.NODE_ENV === "production") ? config.state.appid_prod : config.state.appid_dev;

      ApiService.setHeader();

      for (const [key, val] of Object.entries(credentials)) {
        switch (key) {
          case 'id':
          case 'request_amount':
          case 'request_inst':
          case 'request_tenure':
          case 'request_rate':
          case 'request_deductions':
            _context[key] = val;
            break;
          default:
        }
      }
      return ApiService.put("/apps/" + credentials.id, _context);
    }
  },

  async [DELETE_APPLICATION](context, credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      await ApiService.delete("/apps/" + credentials.id)
        .then((response) => {
          context.commit(PURGE_APPLICATION_SPECIFIC, credentials);
        });
    }
  },

  async [COMPLETE_APPLICATION](context, credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/complete/" + credentials);
    }
  },

  async [GET_ASSIGNED_APPLICATIONS_BY_BRANCH](context, credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return await ApiService.post("/app/assign/branch");
    }
  },

  async [GET_UNASSIGNED_APPLICATIONS_BY_BRANCH](context, credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return await ApiService.post("/app/unassign/branch");
    }
  },

  async [GET_COMPLETE_APPLICATIONS](context, credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return ApiService.post("/apps/complete");
    }
  },

  async [GET_APPROVED_APPLICATIONS_SO](context, credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return ApiService.post("/apps/getApprApps");
    }
  },

  async [GET_APPROVED_APPLICATIONS_CS](context, credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return ApiService.post("/apps/cs/approved/get");
    }
  },

  async [ASSIGN_APPLICATION](context, credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return await ApiService.post("/app/assign", credentials);
    }
  },

  async [ASSIGN_APPLICATION_BY_EMAIL](context, credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeaderByParam(credentials.token);
      return ApiService.post("/app/assign/email", credentials);
    }
  },

  async [DELETE_ASSIGNED_APPLICATION](context, credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return await ApiService.post("/app/assign/del", credentials)
    }
  },

  async [GET_APPLICATIONS_BY_PAGE](context, credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return axios.get(credentials);
    }
  },

  async [GET_APPS_BY_URL](context, credentials) {
    var _ret = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      await axios.get(credentials).then((resp) => {
        const _res = makeDataReadable(resp?.data);

        if (_res?.success) {
          //nfh-console.log(`[${credentials}] GET_APPS_BY_URL <fetched>`, _res);
          context.commit(SET_PAGED_APPS_ROLE_MEMBER, _res?.data);
          _ret = _res;
        }
        else {
          //nfh-console.log(`[${credentials}] GET_APPS_BY_URL <FAILED>`, resp);
        }
      })
        .catch(err => {
          //nfh-console.log(`[${credentials}] GET_APPS_BY_URL <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${credentials}] GET_APPS_BY_URL <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${credentials}] GET_APPS_BY_URL <getToken FAILED>`);
    }

    return _ret;
  },

  // For Processing
  async [GET_REMARK_APPLICATIONS](context, credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get("processing/getAppWithRemarks");
    }
  },

  async [GET_APPROVED_APPLICATIONS](context, credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get("processing/getApprApps");
    }
  },

  async [REJECT_APPLICATION](context, credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return await ApiService.post("processing/rejectApplication", {
        application_id: credentials.application_id,
        description: credentials.description,
      });
    }
  },

  async [PROCESS_TO_ILMS](context, credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.post("/post/apps", {
        application_id: credentials.application_id,
      });
    }
  },

  // async [UPLOAD_MEDIAS](context, credentials) {
  //   if (JwtService.getToken()) {
  //     ApiService.setHeader();
  //     await ApiService.post("/apps/" + credentials.id + "/uploadFile");
  //   }
  // },

  // async [DELETE_MEDIA](context, credentials) {
  //   if(JwtService.getToken()) {
  //     ApiService.setHeader();
  //     await ApiService.post("/apps/" + credentials.id + "/deleteMedia/" + "/mediaId");
  //   }
  // }

  async [GET_CHECKLIST_APP_FULL](context, credentials) {
    const apiRoute = "/apps/chklist2";
    var _ret = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      await ApiService.post(apiRoute, { id: credentials })
        .then((resp) => {
          const _res = makeDataReadable(resp?.data);

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] GET_CHECKLIST_APP_FULL <fetched>`, _res);
            context.commit(SET_CHECKLIST_APP_FULL, _res?.data);

            _ret = _res.data;
          }
          else {
            _ret = _res;
            //nfh-console.log(`[${apiRoute}] GET_CHECKLIST_APP_FULL <FAILED>`, resp);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] GET_CHECKLIST_APP_FULL <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] GET_CHECKLIST_APP_FULL <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] GET_CHECKLIST_APP_FULL <getToken FAILED>`);
    }

    return _ret;
  },

  async [UPDATE_MEMBER](context, credentials) {
    const apiRoute = `/mbr/${credentials.id}/upd`;
    var _ret       = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

    //  return await ApiService.post(apiRoute, credentials)
    //     .then((resp) => {
    //       console.log(`[${apiRoute}] UPDATE_MEMBER <fetched>`, resp.data);
    //       context.commit(SET_MEMBER, resp.data);

    //       return resp.data;
    //     });
    // }
      await ApiService.post(apiRoute, credentials)
        .then((resp) => {
          const _res = makeDataReadable(resp?.data);

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] UPDATE_MEMBER <fetched>`, resp);
            context.commit(SET_MEMBER, _res?.data);
            _ret = _res;
          }
          else {
            _ret = _res;
            //nfh-console.log(`[${apiRoute}] UPDATE_MEMBER <FAILED>`, resp);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] UPDATE_MEMBER <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] UPDATE_MEMBER <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] UPDATE_MEMBER <getToken FAILED>`);
    }

    return _ret;
  },

  async [UPDATE_EMPLOYMENT](context, credentials) {
    const apiRoute = `/emp/${credentials.id}/upd`;
    var _ret       = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      await ApiService.post(apiRoute, credentials)
        .then((resp) => {
          const _res = makeDataReadable(resp?.data);

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] UPDATE_EMPLOYMENT <fetched>`, resp);
            context.commit(SET_EMPLOYMENT, _res?.data);
            _ret = _res;
          }
          else {
            _ret = _res;
            //nfh-console.log(`[${apiRoute}] UPDATE_EMPLOYMENT <FAILED>`, resp);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] UPDATE_EMPLOYMENT <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] UPDATE_EMPLOYMENT <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] UPDATE_EMPLOYMENT <getToken FAILED>`);
    }

    return _ret;
  },

  async [UPDATE_HEIR](context, credentials) {
    const apiRoute = `/wrs/${credentials.id}/upd`;
    var _ret = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      // return await ApiService.post(apiRoute, credentials)
      //   .then((resp) => {
      //     console.log(`[${apiRoute}] UPDATE_HEIR <fetched>`, resp);
      //     context.commit(SET_HEIR, resp.data.data);

      //     return resp.data;
      //   });
      await ApiService.post(apiRoute, credentials)
        .then((resp) => {
          const _res = makeDataReadable(resp?.data);

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] UPDATE_HEIR <fetched>`, resp);
            context.commit(SET_HEIR, _res?.data);
            _ret = _res;
          }
          else {
            _ret = _res;
            //nfh-console.log(`[${apiRoute}] UPDATE_HEIR <FAILED>`, resp);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] UPDATE_HEIR <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] UPDATE_HEIR <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] UPDATE_HEIR <getToken FAILED>`);
    }

    return _ret;
  },

};

//==================================================================================================
// mutations
//
const mutations = {
  [SET_HEIR](state, heir) {
    state.heir = heir;
    //nfh-console.log('[STATE.application.module] heir <SET>', heir);
  },

  [SET_MEMBER](state, member) {
    state.member = member;
    //nfh-console.log('[STATE.application.module] member <SET>', member);
  },

  [SET_EMPLOYMENT](state, employment) {
    state.employment = employment;
    //nfh-console.log('[STATE.application.module] employment <SET>', employment);
  },

  [SET_APPLICATION](state, application) {
    state.application = application;
    //nfh-console.log('[STATE.application.module] application <SET>', application);
  },

  [SET_APPLICATIONS](state, applications) {
    state.applications = applications;
    //nfh-console.log('[STATE.applications.module] applications <SET>', applications);
  },

  [SET_FINANCING_APP](state, financing) {
    state.member.financing_req_tenure    = financing?.financing_req_tenure;
    state.member.financing_req_amount    = financing?.financing_req_amount;
    state.member.financing_req_rate      = financing?.financing_req_rate;
    state.member.financing_req_inst      = financing?.financing_req_inst;
    state.member.financing_req_deds      = financing?.financing_req_deds;
    state.member.financing_calc          = financing?.financing_calc;

    state.employment.salary              = financing?.salary;
    state.employment.employer_type_id    = financing?.employer_type_id;

    state.application.request_tenure     = financing?.financing_req_tenure;
    state.application.request_amount     = financing?.financing_req_amount;
    state.application.request_rate       = financing?.financing_req_rate;
    state.application.request_inst       = financing?.financing_req_inst;
    state.application.request_deductions = financing?.financing_req_deds;
    state.application.request_calc       = financing?.financing_calc;

    state.application.employment         = state.employment;
    state.application.member             = state.member;
    //nfh-console.log('[STATE.application.module] application.financing* <SET>', financing);
  },

  [SET_CHECKLIST_APP_FULL](state, checklistAppFull) {
    state.checklistAppFull = checklistAppFull;
    //nfh-console.log('[STATE.application.module] checklistAppFull <SET>', checklistAppFull);
  },

  [SET_UNASSIGNED_APPLICATIONS](state, unassignedApplications) {
    state.unassignedApplications = unassignedApplications;
    //nfh-console.log('[STATE.application.module] unassignedApplications <SET>', unassignedApplications);
  },

  [SET_COMPLETE_APPLICATIONS](state, completeApplications) {
    state.completeApplications = completeApplications;
    //nfh-console.log('[STATE.application.module] completeApplications <SET>', completeApplications);
  },

  [SET_APPROVED_APPLICATIONS](state, approvedApplications) {
    state.approvedApplications = approvedApplications;
    //nfh-console.log('[STATE.application.module] approvedApplications <SET>', approvedApplications);
  },

  [SET_RETURNED_APPLICATIONS](state, returnedApplications) {
    state.returnedApplications = returnedApplications;
    //nfh-console.log('[STATE.application.module] returnedApplications <SET>', returnedApplications);
  },

  [SET_PAGED_APPS_ROLE_MEMBER](state, pagedAppsRoleMember) {
    state.pagedAppsRoleMember = {};
    state.pagedAppsRoleMember = pagedAppsRoleMember;
    //nfh-console.log('[STATE.application.module] pagedAppsRoleMember <SET>', pagedAppsRoleMember);
  },

  [SET_PAGED_APPS_ROLE_PROC_RCVD](state, pagedAppsRoleProcRcvd) {
    state.pagedAppsRoleProcRcvd = {};
    state.pagedAppsRoleProcRcvd = pagedAppsRoleProcRcvd;
    //nfh-console.log('[STATE.application.module] pagedAppsRoleProcRcvd <SET>', pagedAppsRoleProcRcvd);
  },

  [SET_PAGED_APPS_ROLE_PROC_TOAPPR](state, pagedAppsRoleProcToAppr) {
    state.pagedAppsRoleProcToAppr = {};
    state.pagedAppsRoleProcToAppr = pagedAppsRoleProcToAppr;
    //nfh-console.log('[STATE.application.module] pagedAppsRoleProcToAppr <SET>', pagedAppsRoleProcToAppr);
  },

  [SET_PAGED_APPS_ROLE_PROC_REMARK](state, pagedAppsRoleProcRemark) {
    state.pagedAppsRoleProcRemark = {};
    state.pagedAppsRoleProcRemark = pagedAppsRoleProcRemark;
    //nfh-console.log('[STATE.application.module] pagedAppsRoleProcRemark <SET>', pagedAppsRoleProcRemark);
  },

  [SET_PAGED_APPS_ROLE_PROC_APPRVD](state, pagedAppsRoleProcApprvd) {
    state.pagedAppsRoleProcApprvd = {};
    state.pagedAppsRoleProcApprvd = pagedAppsRoleProcApprvd;
    //nfh-console.log('[STATE.application.module] pagedAppsRoleProcApprvd <SET>', pagedAppsRoleProcApprvd);
  },

  [SET_PAGED_APPS_ROLE_PROC_REJECT](state, pagedAppsRoleProcReject) {
    state.pagedAppsRoleProcReject = {};
    state.pagedAppsRoleProcReject = pagedAppsRoleProcReject;
    //nfh-console.log('[STATE.application.module] pagedAppsRoleProcReject <SET>', pagedAppsRoleProcReject);
  },

  [SET_PAGINATE_APPLICATIONS](state, paginateApplications) {
    state.paginateApplications = {};
    state.paginateApplications = paginateApplications;
    //nfh-console.log('[STATE.application.module] paginateApplications <SET>', paginateApplications);
  },

  [SET_PAGINATE_REMARK_APPLICATION](state, paginateRemarkApplications) {
    state.paginateRemarkApplications = {};
    state.paginateRemarkApplications = paginateRemarkApplications;
    //nfh-console.log('[STATE.application.module] paginateRemarkApplications <SET>', paginateRemarkApplications);
  },

  [SET_PAGINATE_UNASSIGNED_APPLICATIONS](state, paginateUnassignedApplications) {
    state.paginateUnassignedApplications = {};
    state.paginateUnassignedApplications = paginateUnassignedApplications;
    //nfh-console.log('[STATE.application.module] paginateUnassignedApplications <SET>', paginateUnassignedApplications);
  },

  [SET_PAGED_APPS_ROLE_SALES_ASSIST](state, pagedAppsRoleSalesAssistable) {
    state.pagedAppsRoleSalesAssistable = {};
    state.pagedAppsRoleSalesAssistable = pagedAppsRoleSalesAssistable;
    //nfh-console.log('[STATE.application.module] pagedAppsRoleSalesAssistable <SET>', pagedAppsRoleSalesAssistable);
  },

  [SET_PAGED_APPS_ROLE_SALES_REMARK](state, pagedAppsRoleSalesRemarked) {
    state.pagedAppsRoleSalesRemarked = {};
    state.pagedAppsRoleSalesRemarked = pagedAppsRoleSalesRemarked;
    //nfh-console.log('[STATE.application.module] pagedAppsRoleSalesRemarked <SET>', pagedAppsRoleSalesRemarked);
  },

  [SET_PAGED_APPS_ROLE_SALES_PROCESS](state, pagedAppsRoleSalesProcess) {
    state.pagedAppsRoleSalesProcess = {};
    state.pagedAppsRoleSalesProcess = pagedAppsRoleSalesProcess;
    //nfh-console.log('[STATE.application.module] pagedAppsRoleSalesProcess <SET>', pagedAppsRoleSalesProcess);
  },

  [SET_PAGED_APPS_ROLE_SALES_APPRVD](state, pagedAppsRoleSalesApproved) {
    state.pagedAppsRoleSalesApproved = {};
    state.pagedAppsRoleSalesApproved = pagedAppsRoleSalesApproved;
    //nfh-console.log('[STATE.application.module] pagedAppsRoleSalesApproved <SET>', pagedAppsRoleSalesApproved);
  },

  [UNSET_APPLICATION](state) {
    state.application = null;
    //nfh-console.log('[STATE.application.module] application <UNSET>');
  },

  [UNSET_APPLICATIONS](state) {
    state.applications = [];
    //nfh-console.log('[STATE.application.module] applications <UNSET>');

  },

  [UNSET_CHECKLIST_APP_FULL](state) {
    state.checklistAppFull = [];
    //nfh-console.log('[STATE.application.module] checklistAppFull <UNSET>');

  },

  [UNSET_UNASSIGNED_APPLICATIONS](state) {
    state.unassignedApplications = [];
    //nfh-console.log('[STATE.application.module] unassignedApplications <UNSET>');

  },

  [UNSET_COMPLETE_APPLICATIONS](state) {
    state.completeApplications = {};
    //nfh-console.log('[STATE.application.module] completeApplications <UNSET>');

    // xxconsole.log(state.completeApplications);
  },

  [UNSET_APPROVED_APPLICATIONS](state) {
    state.approvedApplications = {};
    //nfh-console.log('[STATE.application.module] approvedApplications <UNSET>');

  },

  [UNSET_RETURNED_APPLICATIONS](state) {
    state.returnedApplications = {};
    //nfh-console.log('[STATE.application.module] returnedApplications <UNSET>');

  },

  [UNSET_PAGED_APPS_ROLE_MEMBER](state) {
    state.pagedAppsRoleMember = {};
    //nfh-console.log('[STATE.application.module] pagedAppsRoleMember <UNSET>');

  },

  [UNSET_PAGINATE_APPLICATIONS](state) {
    state.paginateApplications = {};
    //nfh-console.log('[STATE.application.module] paginateApplications <UNSET>');

  },

  [UNSET_PAGINATE_REMARK_APPLICATION](state) {
    state.paginateRemarkApplications = {};
    //nfh-console.log('[STATE.application.module] paginateRemarkApplications <UNSET>');

  },

  [UNSET_PAGINATE_UNASSIGNED_APPLICATIONS](state) {
    state.paginateUnassignedApplications = {};
    //nfh-console.log('[STATE.application.module] paginateUnassignedApplications <UNSET>');

  },

  [UNSET_PG_ASSISTABLE_APPLICATIONS](state) {
    state.pagedAppsRoleSalesAssistable = {};
    //nfh-console.log('[STATE.application.module] pagedAppsRoleSalesAssistable <UNSET>');
  },

  [UNSET_PAGED_APPS_ROLE_SALES_ASSIST](state) {
    state.pagedAppsRoleSalesAssistable = {};
    //nfh-console.log('[STATE.application.module] pagedAppsRoleSalesAssistable <UNSET>');
  },

  [UNSET_PAGED_APPS_ROLE_SALES_REMARK](state) {
    state.pagedAppsRoleSalesRemarked = {};
    //nfh-console.log('[STATE.application.module] pagedAppsRoleSalesRemarked <UNSET>');
  },

  [UNSET_PAGED_APPS_ROLE_SALES_PROCESS](state) {
    state.pagedAppsRoleSalesProcess = {};
    //nfh-console.log('[STATE.application.module] pagedAppsRoleSalesProcess <UNSET>');
  },

  [UNSET_PAGED_APPS_ROLE_SALES_APPRVD](state) {
    state.pagedAppsRoleSalesApproved = {};
    //nfh-console.log('[STATE.application.module] pagedAppsRoleSalesApproved <UNSET>');
  },

  [PURGE_APPLICATION_SPECIFIC](state, application) {
    var index = state.applications.findIndex(_app => _app.id == application.id);
    //console.log(index);
    state.applications.splice(index, 1);
    //nfh-console.log(`[STATE.application.module] applications[${index}] <PURGED>`, state.applications);
  },

  async [PURGE_HEIR](state) {
    state.heir = {};
    //nfh-console.log('[STATE.form.module] heir <PURGED>', state.heir);
  },

  async [PURGE_MEMBER](state) {
    state.member = {};
    //nfh-console.log('[STATE.form.module] member <PURGED>', state.member);
  },

  async [PURGE_EMPLOYMENT](state) {
    state.employment = {};
    //nfh-console.log('[STATE.form.module] employment <PURGED>', state.employment);
  },

  [PURGE_ALL](state) {
    state.heir                           = null;
    state.member                         = null;
    state.employment                     = null;
    state.application                    = null;
    state.applications                   = [];
    state.checklistAppFull               = [];
    state.unassignedApplications         = [];
    state.completeApplications           = {};
    state.approvedApplications           = {};
    state.returnedApplications           = {};
    state.paginateApplications           = {};
    state.paginateRemarkApplications     = {};
    state.paginateUnassignedApplications = {};
    state.pagedAppsRoleProcRcvd          = {};
    state.pagedAppsRoleMember            = {};
    state.pagedAppsRoleSalesAssistable   = {};
    state.pagedAppsRoleSalesRemarked     = {};
    state.pagedAppsRoleSalesProcess      = {};
    state.pagedAppsRoleSalesApproved     = {};
    state.pagedAppsRoleProcToAppr        = {};
    state.pagedAppsRoleProcRemark        = {};
    state.pagedAppsRoleProcApprvd        = {};
    state.pagedAppsRoleProcReject        = {};
    //nfh-console.log(`[STATE.application.module] PURGE_ALL <PURGED>`, state);
  },

};

//==================================================================================================
// exports
//
export default {
  state,
  actions,
  mutations,
  getters,
};
