import Vue from "vue";
import Router from "vue-router";
import { publicPath } from "../vue.config";

Vue.use(Router);

export default new Router({
  base: publicPath,
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          name: "Dashboard",
          path: "/dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
          props: true,
          meta: {
            requiredAuthorization: true,
            // roles: ['Member', 'Public']
            roles: [5, 6]
          }
        },
        {
          name: "Form",
          path: "/form",
          component: () => import("@/view/pages/form/Form.vue"),
          props: true,
          meta: {
            requiredAuthorization: true,
            // roles: ['Member', 'Public']
            roles: [5, 6]
          }
        },
        {
          name: "EditForm",
          path: "/edit/:id",
          component: () => import("@/view/pages/form/EditForm.vue"),
          props: true
        },
        {
          name: "Notification",
          path: "/notification",
          component: () => import("@/view/pages/notifications/Notification.vue"),
          props: true,
        },
        {
          name: "Review",
          path: "/review",
          component: () =>
            import(/* webpackChunkName: "" */ "@/view/pages/Review"),
          props: true
        },
        {
          name: "ChangePassword",
          path: "/change-password",
          component: () =>
            import(/* webpackChunkName: "" */ "@/view/pages/profile/profile-comp/ChangePassword"),
          props: true
        },
        {
          name: "Builder",
          path: "/builder",
          component: () => import("@/view/pages/Builder.vue")
        },
        {
          name: "Profile",
          path: "/profile",
          component: () => import("@/view/pages/profile/Profile.vue"),
          children: [
            {
              name: "Profile-1",
              path: "profile-1",
              component: () => import("@/view/pages/profile/Profile-1.vue")
            }
          ]
        },
        {
          name: "ApplicationByBatch",
          path: "/applicationByBatch/:batch_no/:detail",
          component: () => import("@/view/pages/approver/ApplicationByBatch.vue"),
          props: true
        },
        {
          name: "ApplicationDetail",
          path: "/applicationDetail/:id",
          component: () => import("@/view/pages/approver/ApplicationDetail.vue"),
          props: true
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Login"),
      children: [
        {
          name: "Login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        },
      ]
    },
    {
      name: "Forwarder",
      path: "/fwd",
      component: () => import("@/view/pages/Forwarder.vue"),
      props: true
    },
    {
      name: "Redirect",
      path: "/redirect",
      component: () => import("@/view/pages/Redirector.vue"),
      props: true
    },
    {
      name: "VerifyEmail",
      path: "/verify-email",
      component: () => import("@/view/pages/VerifyEmail.vue"),
      props: true
    },
    {
      name: "AcceptApplication",
      path: "/accept-application",
      component: () => import("@/view/pages/notifications/AcceptApplication.vue"),
      props: true
    },
    {
      name: "ResetPassword",
      path: "/reset-password",
      component: () => import("@/view/pages/auth/ResetPassword")
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      name: "404",
      path: "/404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ],
});
