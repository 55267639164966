import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import axios      from "axios";

// action types
export const GET_APP_LOGS = "getAppLogs";
export const ADD_APP_LOG  = "addAppLog";

// mutation types
export const SET_APP_LOGS = "setAppLogs";

const state = {
    logs: [],
};

const getters = {
    logs(state) {
        return state.logs;
    }

};

const actions = {
    async [GET_APP_LOGS](context, credentials) {
        if (JwtService.getToken()) {
            ApiService.setHeader();

            return ApiService.post("/apps/logs", credentials);
        }
    },

    async [ADD_APP_LOG](context, credentials) {
        if (JwtService.getToken()) {
            ApiService.setHeader();

            return ApiService.post("/apps/logs/add", credentials);
        }
    }
};

const mutations = {
    [SET_APP_LOGS](state, logs) {
        state.logs = logs;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
};