import ApiService from "@/core/services/api.service";
import JwtService from "../jwt.service";

// action types
export const SEND_OTP = "sendOTP";
export const RESEND_OTP = "resendOTP";
export const VERIFY_OTP = "verifyOTP";
export const VERIFY_OTP_PHONE = "verifyOTPPhone";


// mutation types

const state = {
  otp: ""
};

const getters = {
  otp(state) {
    return state.otp;
  }
}

const actions = {
  async [SEND_OTP](context, credentials) {
    if (credentials) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        return await ApiService.post("/otpany", credentials);
      }
    }
  },

  async [RESEND_OTP](context, credentials) {
    if (credentials) {
      // return await ApiService.post("/auth/otp/resend", credentials);
      return ApiService.post("/auth/otp/resend", credentials);
    }
  },

  async [VERIFY_OTP](context, credentials) {
    if (credentials) {
      return await ApiService.post("/auth/otp/verify", credentials);
    }
  },

  async [VERIFY_OTP_PHONE](context, credentials) {
    if (credentials) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        return await ApiService.post("/auth/verifyotp/phone", credentials);
      }
    }
  },

}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}