// action types
export const UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
export const UPDATE_ACCOUNT_INFO = "updateUserAccountInfo";

// mutation types
export const SET_PERSONAL_INFO = "setPersonalInfo";
export const SET_ACCOUNT_INFO = "setAccountInfo";

const state = {
  // user_personal_info: {
  //   photo: "/media/users/300_21.jpg",
  //   name: "Muhammad Ali bin Abu",
  //   nric: "700711-10-0007",
  //   surname: "Abu",
  //   nickname: "Ali",
  //   company_name: "Kowamas",
  //   job: "Application Developer",
  //   email: "muhammad.ali@boxing.com",
  //   phone: "0123456789",
  //   company_site: "www.kowamas.com"
  // },
  // user_account_info: {
  //   username: "nick84",
  //   memberNo: "",
  //   email: "muhammad.ali@boxer.com",
  //   language: "English",
  //   time_zone: "(GMT+08:00) Kuala Lumpur",
  //   communication: {
  //     email: true,
  //     sms: true,
  //     phone: false
  //   },
  //   verification: true
  // }
  user_personal_info: {
    photo: "/media/users/default.jpg",
    name: "",
    nric: "",
    surname: "",
    nickname: "",
    company_name: "",
    job: "",
    email: "",
    phone: "",
    company_site: ""
  },
  user_account_info: {
    username: "",
    memberNo: "",
    email: "",
    language: "",
    time_zone: "(GMT+08:00) Kuala Lumpur",
    communication: {
      email: true,
      sms: true,
      phone: false
    },
    verification: true
  }
};

const getters = {
  currentUserPersonalInfo(state) {
    return state.user_personal_info;
  },

  currentUserAccountInfo(state) {
    return state.user_account_info;
  },

  currentUserPhoto(state) {
    return state.user_personal_info.photo;
  }
};

const actions = {
  [UPDATE_PERSONAL_INFO](context, payload) {
    context.commit(SET_PERSONAL_INFO, payload);
  },
  [UPDATE_ACCOUNT_INFO](context, payload) {
    context.commit(SET_ACCOUNT_INFO, payload);
  }
};

const mutations = {
  [SET_PERSONAL_INFO](state, user_personal_info) {
    state.user_personal_info = user_personal_info;
  },
  [SET_ACCOUNT_INFO](state, user_account_info) {
    state.user_account_info = user_account_info;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
