<template>
  <v-app>
    <notifications
      group="notifications"
      position="bottom left"
      :speed="500"
    />
    <router-view></router-view>
  </v-app>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main style scss
@import "assets/sass/style.vue";

// Check documentation for RTL css
// Update HTML with RTL attribute at public/index.html
/*@import "assets/css/style.vue.rtl";*/
</style>

<script>
import {
  OVERRIDE_LAYOUT_CONFIG,
} from "@/core/services/store/config.module";

import {
  SAVE_FCM_TOKEN,
} from "@/core/services/store/user.module";

import {
  GET_TESTMODE,
  GET_ONLYACCEPTREALNRIC,
} from "@/core/services/store/setting.module";

export default {
  name: "myE-Wawasan",

  data() {
    return {
      listenersStarted: false,
      idToken: "",
    };
  },

  mounted() {
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */
    this.startListeners();
    // this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
    this.$store.dispatch(GET_TESTMODE);
    this.$store.dispatch(GET_ONLYACCEPTREALNRIC);
  },

  methods: {
    startListeners() {
      this.getIdToken();
      // await this.requestPermission();
      // await this.startOnMessageListener();
      // await this.startTokenRefreshListener();
      // this.listenersStarted = true;
      this.requestPermission();
      this.startOnMessageListener();
      this.startTokenRefreshListener();
      this.listenersStarted = true;
    },

    startOnMessageListener() {
      // xxconsole.log('startOnMessageListener:', 'IN')

      try {
        // xxconsole.info("Defining onMessage function...");

        this.$messaging.onMessage((payload) => {
          // xxconsole.info("Message received : ", payload);
          // xxconsole.log("Message Body : ", payload.notification.body);

          this.$notify({
            group: "notifications",
            title: payload.data ? payload.data.title : (payload.notification ? payload.notification.title : 'Unexpected Notification Received'),
            text: payload.data ? payload.data.body : (payload.notification ? payload.notification.body : JSON.stringify(payload)),
            type: "success",
            duration: 60000,
          });
        });

        this.listenersStarted = true;
        // xxconsole.info("Defining onMessage function", "Done");
      } catch (e) {
        // xxconsole.error("startOnMessageListener Error : ", e);
      }

      // xxconsole.log('startOnMessageListener:', 'OUT')
    },

    // async startTokenRefreshListener() {
    //   console.log('startTokenRefreshListener:', 'IN')
    //
    //   try {
    //     console.info("Defining onTokenRefresh function...");
    //
    //     this.$messaging.onTokenRefresh(async () => {
    //       try {
    //         await this.$messaging.getToken({
    //           // vapidKey: "BJqDBXujAggfTSnB4-Tyx-vFB9JtKqfjcBnBnFh-guwRvyDZy019sIsyvtcwonZWjizOftVRL93fHmvTV5oU3lA",
    //           vapidKey: "BI6BgN1qMrRrueFRCiTDDckMSOCtC1yveEAJEIwFS0yT_draBHz5MMMuTgUNvd-ZfR_wMbD92o6d6qmEVmuMWc8",
    //         });
    //
    //       } catch (e) {
    //         console.error("onTokenRefresh Error : ", e);
    //       }
    //     });
    //     console.info("Defining onTokenRefresh function", "Done");
    //
    //   } catch (e) {
    //     console.error("startTokenRefreshListener Error : ", e);
    //   }
    //
    //   console.log('startTokenRefreshListener:', 'OUT')
    // },
    // async requestPermission() {
    //   try {
    //     console.info("Requesting Notification Permission...");
    //     const permission = await Notification.requestPermission();
    //     console.log("GIVEN notify perms: ", permission);
    //   } catch (e) {
    //     console.error("requestPermission Error : ", e);
    //   }
    // },
    // async getIdToken() {
    //   try {
    //     this.idToken = await this.$messaging.getToken({
    //       // vapidKey: "BJqDBXujAggfTSnB4-Tyx-vFB9JtKqfjcBnBnFh-guwRvyDZy019sIsyvtcwonZWjizOftVRL93fHmvTV5oU3lA",
    //       vapidKey: "BI6BgN1qMrRrueFRCiTDDckMSOCtC1yveEAJEIwFS0yT_draBHz5MMMuTgUNvd-ZfR_wMbD92o6d6qmEVmuMWc8",
    //     });
    //   } catch (e) {
    //     console.error("getIdToken Error : ", e);
    //   }
    // },

    startTokenRefreshListener() {
      try {
        this.$messaging.onTokenRefresh(() => {
          try {
            this.$messaging.getToken({
              // vapidKey: "BJqDBXujAggfTSnB4-Tyx-vFB9JtKqfjcBnBnFh-guwRvyDZy019sIsyvtcwonZWjizOftVRL93fHmvTV5oU3lA",
              vapidKey: "BI6BgN1qMrRrueFRCiTDDckMSOCtC1yveEAJEIwFS0yT_draBHz5MMMuTgUNvd-ZfR_wMbD92o6d6qmEVmuMWc8",
            }).then((currentToken) => {
              this.$store.dispatch(SAVE_FCM_TOKEN, { token: currentToken });
            });
          } catch (e) {
            // xxconsole.error("onTokenRefresh Error : ", e);
          }
        });
      } catch (e) {
        // xxconsole.error("startTokenRefreshListener Error : ", e);
      }
    },

    requestPermission() {
      try {
        Notification.requestPermission();
      } catch (e) {
        // xxconsole.error("requestPermission Error : ", e);
      }
    },

    getIdToken() {
      try {
        this.$messaging.getToken({
          // vapidKey: "BJqDBXujAggfTSnB4-Tyx-vFB9JtKqfjcBnBnFh-guwRvyDZy019sIsyvtcwonZWjizOftVRL93fHmvTV5oU3lA",
          vapidKey: "BI6BgN1qMrRrueFRCiTDDckMSOCtC1yveEAJEIwFS0yT_draBHz5MMMuTgUNvd-ZfR_wMbD92o6d6qmEVmuMWc8",
        })
        .then((currentToken) => {
          Notification.requestPermission();
          this.startOnMessageListener();
          this.startTokenRefreshListener();
          this.$store.dispatch(SAVE_FCM_TOKEN, { token: currentToken });
        })
        .catch((err) => {
          // xxconsole.log("An error occurred while retrieving token. ", err);
        });
      } catch (e) {
        // xxconsole.error("getIdToken Error : ", e);
      }
    },
  },

};
</script>
