import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import axios from "axios";

// action types
export const PDF_BPA = "pdfBPA";
export const SECURED_FILE = "securedFile";
export const GET_FILE_URL = "getFileURL";

// mutation types
export const SET_URL = "setURL";

const state = {
    pdf: null,
    url: '',
};

const getters = {
    url(state) {
        return state.url;
    },

    pdfBPA(state) {
        return state.pdf;
    },
};

const actions = {
    async [SECURED_FILE](context, credentials) {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            return await axios.get(credentials, { responseType: 'blob' });
        }
    },
    async [GET_FILE_URL](context, credentials) {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            return await axios.post(
                credentials
            );
        }
    },
    async [PDF_BPA](context, credentials) {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.setHeaderWithPDF();
            return await axios.get(
                "https://mewbe.novaknowledge.com.my/api/files/b474a904-2fbc-4188-8080-f39b32d92369/111111111111_010814022394_Surat_Tawaran.pdf"
                , credentials
            );
        }
    },
};

const mutations = {
    [SET_URL](state, url) {
        state.url = url;
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};