import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import {
  makeDataReadable,
} from "../helper.service";

// action types
export const GET_LATEST_FORM_SESSION            = "getLatestFormSession";
export const GET_LATEST_HEIR_FORM_SESSION       = "getLatestHeirFormSession";
export const GET_LATEST_EMPLOYMENT_FORM_SESSION = "getLatestEmploymentFormSession";
export const CREATE_MEMBER_SESSION              = "createMemberSession";
export const CREATE_HEIR_SESSION                = "createHeirSession";
export const CREATE_EMPLOYMENT_SESSION          = "createEmploymentSession";
export const CREATE_SIGNATURE_SESSION           = "createSignatureSession";
export const UPDATE_MEMBER_SESSION2             = "updateMemberSession2";
export const UPDATE_FINANCING_SESSION           = "updateFinancingSession";
export const UPDATE_AGREE                       = "updateAgree";
export const UPDATE_ASSIST                      = "updateAssist";
export const UPDATE_NO_ASSIST                   = "updateNoAssist";
export const GET_CHECKLIST_FULL                 = "getChecklistFull";
export const DELETE_FORM_SESSION                = "deleteFormSession";
//======================================================================
// DEPRECATED
// export const UPDATE_MEMBER                      = "updateMember";
// export const UPDATE_EMPLOYMENT                  = "updateEmployment";
// export const UPDATE_HEIR                        = "updateHeir";
// export const DELETE_MEMBER                      = "deleteMember";
// export const DELETE_EMPLOYMENT                  = "deleteEmployment";
// export const DELETE_HEIR                        = "deleteHeir";
// export const GET_LATEST_MEMBER_FORM_SESSION     = "getLatestMemberFormSession";
// export const UPDATE_MEMBER_SESSION              = "updateMemberSession";
// export const GET_CHECKLIST_MASTERS              = "getChecklistMasters";
// export const PURGE_HEIR                         = "purgeHeir";
// export const PURGE_MEMBER                       = "purgeMember";
// export const PURGE_EMPLOYMENT                   = "purgeEmployment";
//======================================================================

// mutation types
export const SET_FORM_SESSION            = "setFormSession";
export const SET_SIGN_FORM_SESSION       = "setSignFormSession";
export const SET_HEIR_FORM_SESSION       = "setHeirFormSession";
export const SET_MEMBER_FORM_SESSION     = "setMemberFormSession";
export const SET_EMPLOYMENT_FORM_SESSION = "setEmploymentFormSession";
export const SET_CHECKLISTS              = "setChecklists";
export const SET_CHECKLIST_FULL          = "setChecklistFull";
export const SET_CHECKLIST_MASTERS       = "setChecklistMasters";
export const SET_CHECKLIST_DOWNLOADS     = "setChecklistDownloads";
export const SET_CHECKLIST_FULFILLED     = "setChecklistFulfilled";
export const SET_SIGNATURE               = "setSignature";
export const SET_AGREE                   = "setAgree";
export const SET_ASSIST                  = "setAssist";
export const SET_FINANCING_FORM          = "setFinancingForm";
export const PURGE_CHECKLIST_MASTERS     = "purgeChecklistMasters";
export const PURGE_ALL_FORM              = "purgeAllForm";

const state = {
  formSession: {},
  signFormSession: {},
  heirFormSession: {},
  memberFormSession: {},
  employmentFormSession: {},
  checklists: [],
  checklistFull: [],
  checklistMasters: [],
  checklistDownloads: [],
  checklistFulfilled: false,
};

const getters = {
  formSession(state) {
    return state.formSession;
  },

  signFormSession(state) {
    return state.signFormSession;
  },

  heirFormSession(state) {
    return state.heirFormSession;
  },

  memberFormSession(state) {
    return state.memberFormSession;
  },

  employmentFormSession(state) {
    return state.employmentFormSession;
  },

  checklists() {
    return state.checklists;
  },

  checklistFull() {
    return state.checklistFull;
  },

  checklistMasters() {
    return state.checklistMasters;
  },

  checklistDownloads() {
    return state.checklistDownloads;
  },

  checklistFulfilled() {
    return state.checklistFulfilled;
  },
};

const actions = {
  async [GET_LATEST_FORM_SESSION](context, params) {
    var _ret = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      const apiRoute = "/sess/get";

      await ApiService.post(apiRoute)
        .then((result) => {
          const _res = makeDataReadable(result?.data);

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] GET_LATEST_FORM_SESSION <fetched>`, _res);
            // const formData = JSON.parse(Buffer.from(_res, 'base64'));
            context.commit(SET_FORM_SESSION, _res?.data);

            if (_res?.data?.emp ) context.commit(SET_EMPLOYMENT_FORM_SESSION, _res?.data?.emp);
            if (_res?.data?.heir) context.commit(SET_HEIR_FORM_SESSION, _res?.data?.heir);
            if (_res?.data?.sign) context.commit(SET_SIGN_FORM_SESSION, _res?.data.sign);
            // _ret = _res?.data;

            _ret = JSON.parse(JSON.stringify(_res?.data));

            const  _tmp = JSON.parse(JSON.stringify(_res));
            delete _tmp?.data.emp;
            delete _tmp?.data.heir;
            delete _tmp?.data.sign;

            context.commit(SET_MEMBER_FORM_SESSION, _tmp?.data);
          }
          else {
            //nfh-console.log(`[${apiRoute}] GET_LATEST_FORM_SESSION <FAILED>`, result);
            _ret = _res?.data;
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] GET_LATEST_FORM_SESSION <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] GET_LATEST_FORM_SESSION <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] GET_LATEST_FORM_SESSION <getToken FAILED>`);
    }

    return _ret;
  },

  async [GET_LATEST_HEIR_FORM_SESSION](context, params) {
    var _ret = null;

    if (JwtService.getToken()) {
      await ApiService.setHeader();

      const apiRoute = "/sess/wrs/get";

      await ApiService.post(apiRoute)
        .then((result) => {
          // const response = result?.data?.data;
          const response = makeDataReadable(result?.data);

          if (response?.success) {
            //nfh-console.log(`[${apiRoute}] GET_LATEST_HEIR_FORM_SESSION <fetched>`, response);
            // context.commit(SET_HEIR_FORM_SESSION, JSON.parse(Buffer.from(response, 'base64')));
            context.commit(SET_HEIR_FORM_SESSION, response?.data);
            _ret = response?.data;
          }
          else {
            //nfh-console.log(`[${apiRoute}] GET_LATEST_HEIR_FORM_SESSION <FAILED>`, result);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] GET_LATEST_HEIR_FORM_SESSION <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] GET_LATEST_HEIR_FORM_SESSION <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] GET_LATEST_HEIR_FORM_SESSION <getToken FAILED>`);
    }
    return _ret;
  },

  async [GET_LATEST_EMPLOYMENT_FORM_SESSION](context, params) {
    var _ret = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      const apiRoute = "/sess/emp/get";

      await ApiService.post(apiRoute)
        .then((result) => {
          // const response = result?.data?.data;
          const response = makeDataReadable(result?.data);

          if (response?.success) {
            //nfh-console.log(`[${apiRoute}] GET_LATEST_EMPLOYMENT_FORM_SESSION <fetched>`, response);
            // context.commit(SET_EMPLOYMENT_FORM_SESSION, JSON.parse(Buffer.from(response, 'base64')));
            context.commit(SET_EMPLOYMENT_FORM_SESSION, response?.data);
            _ret = response?.data;
          }
          else {
            //nfh-console.log(`[${apiRoute}] GET_LATEST_EMPLOYMENT_FORM_SESSION <FAILED>`, result);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] GET_LATEST_EMPLOYMENT_FORM_SESSION <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] GET_LATEST_EMPLOYMENT_FORM_SESSION <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] GET_LATEST_EMPLOYMENT_FORM_SESSION <getToken FAILED>`);
    }

    return _ret;
  },

  async [CREATE_MEMBER_SESSION](context, params) {
    var _ret = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      const apiRoute = "/sess/mbr/new";

      await ApiService.post(apiRoute, params)
        .then((result) => {
          const _res = makeDataReadable(result?.data);

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] CREATE_MEMBER_SESSION <fetched>`, _res);
            context.commit(SET_FORM_SESSION, _res?.data);

            if (_res?.data?.emp ) context.commit(SET_EMPLOYMENT_FORM_SESSION, _res?.data?.emp);
            if (_res?.data?.heir) context.commit(SET_HEIR_FORM_SESSION, _res?.data?.heir);
            if (_res?.data?.sign) context.commit(SET_SIGN_FORM_SESSION, _res?.data.sign);

            _ret = JSON.parse(JSON.stringify(_res));

            const  _tmp = JSON.parse(JSON.stringify(_res));
            delete _tmp?.data.emp;
            delete _tmp?.data.heir;
            delete _tmp?.data.sign;

            context.commit(SET_MEMBER_FORM_SESSION, _tmp?.data);
          }
          else {
            //nfh-console.log(`[${apiRoute}] CREATE_MEMBER_SESSION <FAILED>`, result);
            _ret = _res;
          }
        })
        .catch((err) => {
          //nfh-console.log(`[${apiRoute}] CREATE_MEMBER_SESSION <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] CREATE_MEMBER_SESSION <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] CREATE_MEMBER_SESSION <getToken FAILED>`);
    }

    return _ret;
  },

  async [CREATE_HEIR_SESSION](context, params) {
    var _ret = null;

    if (JwtService.getToken()) {
      await ApiService.setHeader();

      const apiRoute = "/sess/wrs/new";

      await ApiService.post(apiRoute, params)
        .then((result) => {
          // const response = result?.data;
          const response = makeDataReadable(result?.data);

          if (response?.success) {
            //nfh-console.log(`[${apiRoute}] CREATE_HEIR_SESSION <fetched>`, response);

            if (response?.data) {
              // context.commit(SET_HEIR_FORM_SESSION, JSON.parse(Buffer.from(response.data, 'base64')));
              context.commit(SET_HEIR_FORM_SESSION, response.data);
            }
            _ret = response;
          }
          else {
            //nfh-console.log(`[${apiRoute}] CREATE_HEIR_SESSION <FAILED>`, result);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] CREATE_HEIR_SESSION <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] CREATE_HEIR_SESSION <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] CREATE_HEIR_SESSION <getToken FAILED>`);
    }

    return _ret;
  },

  async [CREATE_EMPLOYMENT_SESSION](context, params) {
    var _ret = null;

    if (JwtService.getToken()) {
      await ApiService.setHeader();

      const apiRoute = "/sess/emp/new";

      await ApiService.post(apiRoute, params)
        .then((result) => {
          // const response = result?.data;
          const response = makeDataReadable(result?.data);

          if (response.success) {
            //nfh-console.log(`[${apiRoute}] CREATE_EMPLOYMENT_SESSION <fetched>`, response);

            if (response?.data) {
              // context.commit(SET_EMPLOYMENT_FORM_SESSION, JSON.parse(Buffer.from(response.data, 'base64')));
              context.commit(SET_EMPLOYMENT_FORM_SESSION, response.data);
            }
            _ret = response;
          }
          else {
            //nfh-console.log(`[${apiRoute}] CREATE_EMPLOYMENT_SESSION <FAILED>`, result);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] CREATE_EMPLOYMENT_SESSION <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] CREATE_EMPLOYMENT_SESSION <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] CREATE_EMPLOYMENT_SESSION <getToken FAILED>`);
    }

    return _ret;
  },

  async [CREATE_SIGNATURE_SESSION](context, params) {
    var _ret = null;

    if (JwtService.getToken()) {
      await ApiService.setHeader();

      const apiRoute = "/sess/mbr/consent";
      const _token   = params.token ?? "";
      // state.formSession.signature = _token;
      context.commit(SET_SIGNATURE, _token);
      params.token = _token.replace("data:image/png;base64,", "")

      await ApiService.post(apiRoute, params)
        .then((result) => {
          const response = result?.data;

          if (response) {
            //nfh-console.log(`[${apiRoute}] CREATE_SIGNATURE_SESSION <fetched>`, response);
            _ret = response;
          }
          else {
            //nfh-console.log(`[${apiRoute}] CREATE_SIGNATURE_SESSION <FAILED>`, result);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] CREATE_SIGNATURE_SESSION <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] CREATE_SIGNATURE_SESSION <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] CREATE_SIGNATURE_SESSION <getToken FAILED>`);
    }

    return _ret;
  },

  async [UPDATE_MEMBER_SESSION2](context, params) {
    var _ret = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      const apiRoute = `/sess/mbr/${params.id}/upd`;
      // remove non-updatable fields
      // delete params.nric;
      // delete params.user_id;
      // delete params.phone_num;

      await ApiService.post(apiRoute, params)
        .then((result) => {
          // const _res = result?.data;
          const _res = makeDataReadable(result?.data);
          // const decryptedData = JSON.parse(Buffer.from(_res?.data, 'base64'));

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] UPDATE_MEMBER_SESSION2 <fetched>`, _res);

            context.commit(SET_FORM_SESSION, _res?.data);
            if (_res?.data?.emp ) context.commit(SET_EMPLOYMENT_FORM_SESSION, _res?.data.emp);
            if (_res?.data?.heir) context.commit(SET_HEIR_FORM_SESSION, _res?.data.heir);
            if (_res?.data?.sign) context.commit(SET_SIGN_FORM_SESSION, _res?.data.sign);

            const  _tmp = JSON.parse(JSON.stringify(_res));
            delete _tmp?.data.emp;
            delete _tmp?.data.heir;
            delete _tmp?.data.sign;

            context.commit(SET_MEMBER_FORM_SESSION, _tmp?.data);

            // _res.data = decryptedData;
            _ret = _res;
          }
          else {
            //nfh-console.log(`[${apiRoute}] UPDATE_MEMBER_SESSION2 <FAILED>`, result);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] UPDATE_MEMBER_SESSION2 <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] UPDATE_MEMBER_SESSION2 <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] UPDATE_MEMBER_SESSION2 <getToken FAILED>`);
    }

    return _ret;
  },

  async [UPDATE_FINANCING_SESSION](context, params) {
    var _ret = null;

    if (JwtService.getToken()) {
      await ApiService.setHeader();

      const apiRoute = `/sess/fin/upd`;

      await ApiService.post(apiRoute, params)
        .then((result) => {
          const response = result?.data;

          if (response?.data && response.success) {
            //nfh-console.log(`[${apiRoute}] UPDATE_FINANCING_SESSION <fetched>`, response);
            context.commit(SET_FINANCING_FORM, JSON.parse(Buffer.from(response.data, "base64")));
            _ret = response;
          }
          else {
            //nfh-console.log(`[${apiRoute}] UPDATE_FINANCING_SESSION <FAILED>`, result);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] UPDATE_FINANCING_SESSION <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] UPDATE_FINANCING_SESSION <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] UPDATE_FINANCING_SESSION <getToken FAILED>`);
    }

    return _ret;
  },

  async [UPDATE_AGREE](context, params) {
    var _ret = null;

    if (JwtService.getToken()) {
      await ApiService.setHeader();

      const apiRoute = `/sess/agree`;

      await ApiService.post(apiRoute, params)
        .then((result) => {
          const response = result?.data;

          if (response?.data && response.success) {
            //nfh-console.log(`[${apiRoute}] UPDATE_AGREE <fetched>`, response);
            context.commit(SET_AGREE, response.data?.agree_tnc);
            _ret = response;
          }
          else {
            //nfh-console.log(`[${apiRoute}] UPDATE_AGREE <FAILED>`, result);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] UPDATE_AGREE <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] UPDATE_AGREE <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] UPDATE_AGREE <getToken FAILED>`);
    }

    return _ret;
  },

  async [UPDATE_ASSIST](context, params) {
    var _ret = null;

    if (JwtService.getToken()) {
      await ApiService.setHeader();

      const apiRoute = `/sess/assist`;

      await ApiService.post(apiRoute, params)
        .then((result) => {
          const response = result?.data;

          if (response?.data && response.success) {
            //nfh-console.log(`[${apiRoute}] UPDATE_ASSIST <fetched>`, response);
            context.commit(SET_ASSIST, true);
            _ret = response;
          }
          else {
            //nfh-console.log(`[${apiRoute}] UPDATE_ASSIST <FAILED>`, result);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] UPDATE_ASSIST <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] UPDATE_ASSIST <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] UPDATE_ASSIST <getToken FAILED>`);
    }

    return _ret;
  },

  async [UPDATE_NO_ASSIST](context, params) {
    var _ret = null;

    if (JwtService.getToken()) {
      await ApiService.setHeader();

      const apiRoute = `/sess/noassist`;

      await ApiService.post(apiRoute, params)
        .then((result) => {
          const response = result?.data;

          if (response?.data && response.success) {
            //nfh-console.log(`[${apiRoute}] UPDATE_NO_ASSIST <fetched>`, response);
            context.commit(SET_ASSIST, false);
            _ret = response;
          }
          else {
            //nfh-console.log(`[${apiRoute}] UPDATE_NO_ASSIST <FAILED>`, result);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] UPDATE_NO_ASSIST <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] UPDATE_NO_ASSIST <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] UPDATE_NO_ASSIST <getToken FAILED>`);
    }

    return _ret;
  },

  async [GET_CHECKLIST_FULL](context, params) {
    var _ret = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      const apiRoute = "/sess/chklist2";

      await ApiService.post(apiRoute)
        .then((result) => {
          const response = result?.data;

          if (response.success) {
            //nfh-console.log(`[${apiRoute}] GET_CHECKLIST_FULL <fetched>`, response);
            context.commit(SET_CHECKLIST_FULL, JSON.parse(Buffer.from(response.data, 'base64')));
            _ret = response.data;
          }
          else {
            //nfh-console.log(`[${apiRoute}] GET_CHECKLIST_FULL <FAILED>`, result);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] GET_CHECKLIST_FULL <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] GET_CHECKLIST_FULL <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] GET_CHECKLIST_FULL <getToken FAILED>`);
    }

    return _ret;
  },

  async [DELETE_FORM_SESSION](context, params) {
    // return await ApiService.post("/sess/del" + params);
    var _ret = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      const apiRoute = `/sess/del/${params.id}`;

      await ApiService.post(apiRoute)
        .then((result) => {
          const response = result?.data;

          if (response.success) {
            //nfh-console.log(`[${apiRoute}] DELETE_FORM_SESSION <fetched>`, response);
          }
          else {
            //nfh-console.log(`[${apiRoute}] DELETE_FORM_SESSION <FAILED>`, result);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] DELETE_FORM_SESSION <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] DELETE_FORM_SESSION <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] DELETE_FORM_SESSION <getToken FAILED>`);
    }

    return _ret;
  },
};

  // async [UPDATE_MEMBER](context, params) {
  //   if (JwtService.getToken()) {
  //     ApiService.setHeader();

  //     const apiRoute = `/mbr/${params.id}/upd`;

  //     return await ApiService.post(apiRoute, params)
  //       .then((result) => {
  //         console.log(`[${apiRoute}] UPDATE_MEMBER <fetched>`, result.data);
  //         context.commit(SET_MEMBER, result.data);

  //         return result.data;
  //       });
  //   }
  // },

  // async [UPDATE_EMPLOYMENT](context, params) {
  //   if (JwtService.getToken()) {
  //     ApiService.setHeader();

  //     const apiRoute = `/emp/${params.id}/upd`;

  //     return await ApiService.post(apiRoute, params)
  //       .then((result) => {
  //         console.log(`[${apiRoute}] UPDATE_EMPLOYMENT <fetched>`, result);
  //         context.commit(SET_EMPLOYMENT, result.data);

  //         return result.data;
  //       });
  //   }
  // },

  // async [UPDATE_HEIR](context, params) {
  //   if (JwtService.getToken()) {
  //     ApiService.setHeader();

  //     const apiRoute = `/wrs/${params.id}/upd`;

  //     return await ApiService.post(apiRoute, params)
  //       .then((result) => {
  //         console.log(`[${apiRoute}] UPDATE_HEIR <fetched>`, result);
  //         context.commit(SET_HEIR, result.data.data);

  //         return result.data;
  //       });
  //   }
  // },

  // async [GET_LATEST_MEMBER_FORM_SESSION](context, params) {
  //   var _ret = null;

  //   if (JwtService.getToken()) {
  //     ApiService.setHeader();
  //     const apiRoute = "/sess/mbr/get";

  //     await ApiService.post(apiRoute).then((result) => {
  //       const data = result?.data?.data;

  //       if (data) {
  //         console.log(`[${apiRoute}] GET_LATEST_MEMBER_FORM_SESSION <fetched>`, data);
  //         context.commit(SET_MEMBER_FORM_SESSION, JSON.parse(Buffer.from(data, 'base64')));
  //         _ret = data;
  //       }
  //     })
  //     .catch(err => {
  //     });

  //     return _ret;
  //   }
  // },

  // async [UPDATE_MEMBER_SESSION](context, params) {
  //   const apiRoute = "/sess/mbr/upd";
  //   var _ret = null;

  //   if (JwtService.getToken()) {
  //     ApiService.setHeader();

  //     await ApiService.post(apiRoute, params)
  //       .then((result) => {
  //         const data = result?.data?.data;

  //         if (data) {
  //           console.log(`[${apiRoute}] UPDATE_MEMBER_SESSION <fetched>`, data);
  //           // TODO
  //           // Update employmentFormSession, heirFormSession
  //           if (data?.emp) {
  //             context.commit(SET_EMPLOYMENT_FORM_SESSION, data.emp);
  //           }
  //           if (data?.heir) {
  //             context.commit(SET_HEIR_FORM_SESSION, data.heir);
  //           }
  //           _ret = data;
  //         }
  //         else {
  //           console.log(`[${apiRoute}] UPDATE_MEMBER_SESSION <FAILED>`, result);
  //         }
  //       })
  //       .catch(err => {
  //         console.log(`[${apiRoute}] UPDATE_MEMBER_SESSION <CATCH-ERR>`, err);
  //       });

  //     if (!_ret) {
  //       console.log(`[${apiRoute}] UPDATE_MEMBER_SESSION <NULLED>`);
  //     }
  //   }
  //   else {
  //     console.log(`[${apiRoute}] UPDATE_MEMBER_SESSION <getToken FAILED>`);
  //   }

  //   return _ret;
  // },

  // async [GET_CHECKLIST_MASTERS](context, params) {
  //   const apiRoute = "/apps/chklist";
  //   var _ret = null;

  //   if (JwtService.getToken()) {
  //     ApiService.setHeader();

  //     await ApiService.post(apiRoute).then((result) => {
  //       const _res = result?.data;

  //       if (_res.success) {
  //         console.log(`[${apiRoute}] GET_CHECKLIST_MASTERS <fetched>`, _res);
  //         context.commit(SET_CHECKLIST_MASTERS, _res.data);
  //         _ret = _res.data;
  //       }
  //       else {
  //         console.log(`[${apiRoute}] GET_CHECKLIST_MASTERS <FAILED>`, result);
  //       }
  //     })
  //     .catch(err => {
  //       console.log(`[${apiRoute}] GET_CHECKLIST_MASTERS <CATCH-ERR>`, err);
  //     });

  //     if (! _ret) {
  //       console.log(`[${apiRoute}] GET_CHECKLIST_MASTERS <NULLED>`);
  //     }
  //   }
  //   else {
  //     console.log(`[${apiRoute}] GET_CHECKLIST_MASTERS <getToken FAILED>`);
  //   }

  //   return _ret;
  // },

const mutations = {
  [SET_FINANCING_FORM](state, financing) {
    state.formSession.emp.salary           = financing?.salary;
    state.formSession.emp.employer_type_id = financing?.employer_type_id;
    state.formSession.financing_req_tenure = financing?.financing_req_tenure;
    state.formSession.financing_req_amount = financing?.financing_req_amount;
    state.formSession.financing_req_rate   = financing?.financing_req_rate;
    state.formSession.financing_req_inst   = financing?.financing_req_inst;
    state.formSession.financing_req_deds   = financing?.financing_req_deds;
    state.formSession.financing_calc       = financing?.financing_calc;
    //nfh-console.log('[STATE.form.module] formSession.financing* <updated>', financing, state.formSession, state.employmentFormSession);
  },

  [SET_AGREE](state, agree) {
    state.formSession.sign.agree_tnc = agree;
    state.signFormSession.agree_tnc = agree;
    //nfh-console.log('[STATE.form.module] formSession.sign.agree_tnc <updated>', agree);
  },

  [SET_ASSIST](state, assistable) {
    state.formSession.sign.assistable = assistable;
    state.signFormSession.assistable = assistable;
    //nfh-console.log('[STATE.form.module] formSession.sign.assistable <updated>', assistable);
  },

  [SET_SIGNATURE](state, signature) {
    state.formSession.sign.signature = signature;
    state.signFormSession.signature = signature;
    //nfh-console.log('[STATE.form.module] formSession.sign.signature <updated>', signature);
  },

  [SET_FORM_SESSION](state, formSession) {
    state.formSession = formSession;
    //nfh-console.log('[STATE.form.module] formSession <updated>', formSession);
  },

  [SET_SIGN_FORM_SESSION](state, signFormSession) {
    state.signFormSession = signFormSession;
    //nfh-console.log('[STATE.form.module] signFormSession <updated>', signFormSession);
  },

  [SET_HEIR_FORM_SESSION](state, heirFormSession) {
    state.heirFormSession = heirFormSession;
    //nfh-console.log('[STATE.form.module] heirFormSession <updated>', heirFormSession);
  },

  [SET_MEMBER_FORM_SESSION](state, memberFormSession) {
    state.memberFormSession = memberFormSession;
    //nfh-console.log('[STATE.form.module] memberFormSession <updated>', memberFormSession);
  },

  [SET_EMPLOYMENT_FORM_SESSION](state, employmentFormSession) {
    state.employmentFormSession = employmentFormSession;
    //nfh-console.log('[STATE.form.module] employmentFormSession <updated>', employmentFormSession);
  },

  // [SET_MEMBER](state, member) {
  //   state.member = member;
  //   console.log('[STATE.form.module] member <updated>', member);
  // },

  // [SET_EMPLOYMENT](state, employment) {
  //   state.employment = employment;
  //   console.log('[STATE.form.module] employment <updated>', employment);
  // },

  // [SET_HEIR](state, heir) {
  //   state.heir = heir;
  //   console.log('[STATE.form.module] heir <updated>', heir);
  // },

  [SET_CHECKLIST_MASTERS](state, checklistMasters) {
    state.checklistMasters = checklistMasters;
    //nfh-console.log('[STATE.form.module] checklistMasters <updated>', checklistMasters);
  },

  [SET_CHECKLIST_FULL](state, checklistFull) {
    state.checklistFull = checklistFull;
    //nfh-console.log('[STATE.form.module] checklistFull <updated>', checklistFull);
  },

  [SET_CHECKLISTS](state, value) {
    state.checklists = value;
    //nfh-console.log('[STATE.form.module] checklists <updated>', value);
  },

  [SET_CHECKLIST_DOWNLOADS](state, value) {
    state.checklistDownloads = value;
    //nfh-console.log('[STATE.form.module] checklistDownloads <updated>', value);
  },

  [SET_CHECKLIST_FULFILLED](state, value) {
    state.checklistFulfilled = value;
    //nfh-console.log('[STATE.form.module] checklistFulfilled <updated>', value);
  },

  // async [PURGE_HEIR](state) {
  //   state.heir = {};
  //   console.log('[STATE.form.module] heir <PURGED>', state.heir);
  // },

  // async [PURGE_MEMBER](state) {
  //   state.member = {};
  //   console.log('[STATE.form.module] member <PURGED>', state.member);
  // },

  // async [PURGE_EMPLOYMENT](state) {
  //   state.employment = {};
  //   console.log('[STATE.form.module] employment <PURGED>', state.employment);
  // },

  async [PURGE_CHECKLIST_MASTERS](state) {
    state.checklistMasters = [];
    //nfh-console.log('[STATE.form.module] checklistMasters <PURGED>', state.checklistMasters);
  },

  [PURGE_ALL_FORM](state) {
    state.formSession           = {};
    state.signFormSession       = {};
    state.heirFormSession       = {};
    state.memberFormSession     = {};
    state.employmentFormSession = {};
    state.checklists            = [];
    state.checklistFull         = [];
    state.checklistMasters      = [];
    state.checklistDownloads    = [];
    state.checklistFulfilled    = false;
    //nfh-console.log('[STATE.form.module] PURGE_ALL_FORM <PURGED>', state);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
