import Vue from "vue";
import ApiService from "../api.service";
import JwtService from "../jwt.service";

// action types
export const UPLOAD_MEDIA = "uploadMedia";
export const DELETE_MEDIA = "deleteMedia";
export const REFRESH_FILE = "refreshFile";
export const UPLOAD_MEDIA_GUEST = "uploadMediaGuest";
export const DELETE_MEDIA_GUEST = "deleteMediaGuest";
export const REFRESH_FILE_GUEST = "refreshFileGuest";

// mutations

export const SET_CHECKLIST_STAT = "setChecklistStat";
export const SET_CHECKLIST_DATA = "setChecklistData";
export const SET_CHECKLIST_DOWNLOAD_DATA = "setChecklistDownloadData";
export const UNSET_CHECKLIST_DATA = "unsetChecklistData";
export const UNSET_CHECKLIST_DOWNLOAD_DATA = "unsetChecklistDownloadData";
export const PURGE_ALL_CHECKLIST = "purgeAllChecklist";

const state = {
  file: null,
  checklistStore: [],
  checklistDownloadStore: [],
  checklistStatStore: {
    total: 0,
    filled: 0,
    remain: 0,
    needCS: false,
  },
};

const getters = {
  file(state) {
    return state.file;
  },

  checklistStore(state) {
      return state.checklistStore;
  },

  checklistDownloadStore(state) {
      return state.checklistDownloadStore;
  },

  checklistStatStore(state) {
      return state.checklistStatStore;
  },

};

const actions = {
  async [UPLOAD_MEDIA](context, credentials) {
    //nfh-console.log(" IN: UPLOAD_MEDIA", credentials);

    const apiRoute = `/apps/${credentials.applicationId}/uploadMedia`;
    var _ret = null;

    if (JwtService.getToken()) {
      let formData = new FormData();

      formData.append("name", credentials.name);
      formData.append("media", credentials.media);
      formData.append("data", credentials.data);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const newStat = Object.assign({}, state.checklistStatStore);

      await Vue.axios.post(apiRoute, formData, config)
        .then((resp) => {
          const data = resp?.data;

          if (data?.success) {
            newStat.filled += 1;
            newStat.remain -= 1;

            context.commit(SET_CHECKLIST_STAT, newStat);
            //nfh-console.log(`[${apiRoute}] UPLOAD_MEDIA <fetched>`, data);
            _ret = resp;
          }
          else {
            //nfh-console.log(`[${apiRoute}] UPLOAD_MEDIA <FAILED>`, resp);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] UPLOAD_MEDIA <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] UPLOAD_MEDIA <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] UPLOAD_MEDIA <getToken FAILED>`);
    }

    //nfh-console.log("OUT: UPLOAD_MEDIA");
    return _ret;
  },

  async [UPLOAD_MEDIA_GUEST](context, credentials) {
    //nfh-console.log(" IN: UPLOAD_MEDIA_GUEST", credentials);

    const apiRoute = `/sess/${credentials.formId}/uploadMedia`;
    var _ret = null;

    if (JwtService.getToken()) {
      let formData = new FormData();

      formData.append("name", credentials.name);
      formData.append("media", credentials.media);
      formData.append("data", credentials.data);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      const newStat = Object.assign({}, state.checklistStatStore);

      await Vue.axios.post(apiRoute, formData, config)
        .then((resp) => {
          const data = resp?.data;

          if (data?.success) {
            newStat.filled += 1;
            newStat.remain -= 1;

            context.commit(SET_CHECKLIST_STAT, newStat);
            //nfh-console.log(`[${apiRoute}] UPLOAD_MEDIA_GUEST <fetched>`, data);
            _ret = resp;
          }
          else {
            //nfh-console.log(`[${apiRoute}] UPLOAD_MEDIA_GUEST <FAILED>`, resp);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] UPLOAD_MEDIA_GUEST <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] UPLOAD_MEDIA_GUEST <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] UPLOAD_MEDIA_GUEST <getToken FAILED>`);
    }

    //nfh-console.log("OUT: UPLOAD_MEDIA_GUEST");
    return _ret;
  },

  async [REFRESH_FILE](context, credentials) {
    //nfh-console.log(" IN: REFRESH_FILE", credentials);

    const apiRoute = "/pdf/regenerate";
    var _ret = null;

    if (credentials) {
      if (JwtService.getToken()) {
        ApiService.setHeader();

        await ApiService.post(apiRoute, credentials)
          .then((resp) => {
            const data = resp?.data;

            if (data?.success) {
              resp.data.data = JSON.parse(Buffer.from(data.data, 'base64'));
              _ret = resp;
              //nfh-console.log(`[${apiRoute}] REFRESH_FILE <fetched>`, data);
            }
            else {
              //nfh-console.log(`[${apiRoute}] REFRESH_FILE <FAILED>`, resp);
            }
          })
          .catch(err => {
            //nfh-console.log(`[${apiRoute}] REFRESH_FILE <CATCH-ERR>`, err);
          });

        if (!_ret) {
          //nfh-console.log(`[${apiRoute}] REFRESH_FILE <NULLED>`);
        }
      }
      else {
        //nfh-console.log(`[${apiRoute}] REFRESH_FILE <getToken FAILED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] REFRESH_FILE <credential EMPTY>`);
    }

    //nfh-console.log("OUT: REFRESH_FILE");
    return _ret;
  },

  async [REFRESH_FILE_GUEST](context, credentials) {
    //nfh-console.log(" IN: REFRESH_FILE_GUEST", credentials);

    const apiRoute = "/pdf/regenerate_guest";
    var _ret = null;

    if (credentials) {
      if (JwtService.getToken()) {
        ApiService.setHeader();

        await ApiService.post(apiRoute, credentials)
          .then((resp) => {
            const data = resp?.data;

            if (data?.success) {
              resp.data.data = JSON.parse(Buffer.from(data.data, 'base64'));
              _ret = resp;
              //nfh-console.log(`[${apiRoute}] REFRESH_FILE_GUEST <fetched>`, data, resp);
            }
            else {
              //nfh-console.log(`[${apiRoute}] REFRESH_FILE_GUEST <FAILED>`, resp);
            }
          })
          .catch(err => {
            //nfh-console.log(`[${apiRoute}] REFRESH_FILE_GUEST <CATCH-ERR>`, err);
          });

        if (!_ret) {
          //nfh-console.log(`[${apiRoute}] REFRESH_FILE_GUEST <NULLED>`);
        }
      }
      else {
        //nfh-console.log(`[${apiRoute}] REFRESH_FILE_GUEST <getToken FAILED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] REFRESH_FILE_GUEST <credential EMPTY>`);
    }

    //nfh-console.log("OUT: REFRESH_FILE_GUEST");
    return _ret;
  },

  async [DELETE_MEDIA](context, credentials) {
    //nfh-console.log(" IN: DELETE_MEDIA", credentials);

    const apiRoute = `/deleteMedia/${credentials.id}`;
    var _ret = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      const newStat = Object.assign({}, state.checklistStatStore);

      // return await ApiService.post(apiRoute);
      await ApiService.post(apiRoute)
        .then((resp) => {
          const data = resp?.data;

          if (data?.success) {
            newStat.filled -= 1;
            newStat.remain += 1;

            context.commit(SET_CHECKLIST_STAT, newStat);
            //nfh-console.log(`[${apiRoute}] DELETE_MEDIA <fetched>`, data);
            _ret = resp;
          }
          else {
            //nfh-console.log(`[${apiRoute}] DELETE_MEDIA <FAILED>`, resp);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] DELETE_MEDIA <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] DELETE_MEDIA <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] DELETE_MEDIA <getToken FAILED>`);
    }

    //nfh-console.log("OUT: DELETE_MEDIA");
    return _ret;
  },

  async [DELETE_MEDIA_GUEST](context, credentials) {
    //nfh-console.log(" IN: DELETE_MEDIA_GUEST", credentials);

    const apiRoute = `/sess/${credentials.id}/deleteMedia`;
    var _ret = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      const newStat = Object.assign({}, state.checklistStatStore);

      // return await ApiService.post(apiRoute);
      await ApiService.post(apiRoute)
        .then((resp) => {
          const data = resp?.data;

          if (data?.success) {
            newStat.filled -= 1;
            newStat.remain += 1;

            context.commit(SET_CHECKLIST_STAT, newStat);
            //nfh-console.log(`[${apiRoute}] DELETE_MEDIA_GUEST <fetched>`, data);
            _ret = resp;
          }
          else {
            //nfh-console.log(`[${apiRoute}] DELETE_MEDIA_GUEST <FAILED>`, resp);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] DELETE_MEDIA_GUEST <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] DELETE_MEDIA_GUEST <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] DELETE_MEDIA_GUEST <getToken FAILED>`);
    }

    //nfh-console.log("OUT: DELETE_MEDIA_GUEST");
    return _ret;
  },

};

const mutations = {
  [SET_CHECKLIST_STAT](state, checklistStatStore) {
    state.checklistStatStore = checklistStatStore;
    //nfh-console.log('[STATE.mediaupload.module] checklistStatStore <updated>', checklistStatStore);
  },

  [SET_CHECKLIST_DATA](state, checklistStore) {
    var _stats = {
      total: 0,
      filled: 0,
      remain: 0,
      needCS: state?.checklistStatStore?.needCS,
    };

    state.checklistStore = checklistStore;
    //nfh-console.log('[STATE.mediaupload.module] checklistStore <updated>', checklistStore);

    try {
      if (Array.isArray(checklistStore)) {
        checklistStore.forEach(_el => {
          if (_el.mandatory) {
            _stats.total++;
            _stats.filled += _el.checklist_id ? 1 : 0;
            _stats.remain = _stats.total - _stats.filled;
          }
        });

        state.checklistStatStore = _stats;
      }
    } catch(err) {
    }
  },

  [SET_CHECKLIST_DOWNLOAD_DATA](state, checklistDownloadStore) {
    state.checklistDownloadStore = checklistDownloadStore;
    //nfh-console.log('[STATE.mediaupload.module] checklistDownloadStore <updated>', checklistDownloadStore);
  },

  [UNSET_CHECKLIST_DATA](state) {
    state.checklistStore = [];
    state.checklistStatStore = {
      total: 0,
      filled: 0,
      remain: 0,
      needCS: false,
    };
    //nfh-console.log('[STATE.mediaupload.module] checklistStore <unset>');
  },

  [UNSET_CHECKLIST_DOWNLOAD_DATA](state) {
    state.checklistDownloadStore = [];
    //nfh-console.log('[STATE.mediaupload.module] checklistDownloadStore <unset>');
  },

  [PURGE_ALL_CHECKLIST](state) {
    state.file = null;
    state.checklistStore = [];
    state.checklistDownloadStore = [];
    state.checklistStatStore = {
      total: 0,
      filled: 0,
      remain: 0,
      needCS: false,
    };
    //nfh-console.log('[STATE.mediaupload.module] PURGE_ALL_CHECKLIST <unset>');
  },

}

export default {
  state,
  actions,
  mutations,
  getters,
};
