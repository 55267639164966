var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "400", "min-height": "200", persistent: _vm.modal },
      model: {
        value: _vm.displayMessageDialog,
        callback: function ($$v) {
          _vm.displayMessageDialog = $$v
        },
        expression: "displayMessageDialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _vm.isTypeError == true
            ? _c(
                "v-toolbar",
                {
                  staticStyle: { "font-size": "12pt" },
                  attrs: {
                    height: "48",
                    elevation: "1",
                    color: "red darken-4",
                    dark: "",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              )
            : _c(
                "v-toolbar",
                {
                  staticStyle: { "font-size": "11pt" },
                  attrs: {
                    height: "48",
                    elevation: "1",
                    color: "#3367D6",
                    dark: "",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.title) + " ")]
              ),
          _c("v-card-text", {
            staticClass: "mt-5",
            staticStyle: { "font-size": "10pt", "text-align": "justify" },
            attrs: { replace: "true" },
            domProps: { innerHTML: _vm._s(_vm.content) },
          }),
          _c("v-spacer"),
          _c("v-divider", { staticClass: "mt-0 mb-0", attrs: { light: "" } }),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm.showBackButton !== false
                ? _c(
                    "v-btn",
                    {
                      attrs: { loading: _vm.isBacking, text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.onBacking()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.backButton) + " ")]
                  )
                : _vm._e(),
              _vm.showProceedButton !== false && _vm.isTypeError == true
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "white--text text--lighten-1 red darken-2",
                        loading: _vm.isProceeding,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.onProceed()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.proceedButton) + " ")]
                  )
                : _vm._e(),
              _vm.showProceedButton !== false && _vm.isTypeError == false
                ? _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", loading: _vm.isProceeding },
                      on: {
                        click: function ($event) {
                          return _vm.onProceed()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.proceedButton) + " ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }