import ApiService from "../api.service";
import {
  makeDataReadable,
} from "../helper.service";

// action types
// export const GET_EMPLOYER_TYPE_LIST = "getEmployerTypes";
export const GET_LOOKUPS                = "getLookups";

// mutation types
export const SET_CITY                   = "setCity";
export const UNSET_CITY                 = "unsetCity";
export const SET_RACE                   = "setRace";
export const UNSET_RACE                 = "unsetRace";
export const SET_BANKS                  = "setBanks";
export const UNSET_BANKS                = "unsetBanks";
export const SET_GENDER                 = "setGender";
export const UNSET_GENDER               = "unsetGender";
export const SET_SALUTE                 = "setSalute";
export const UNSET_SALUTE               = "unsetSalute";
export const SET_STATES                 = "setStates";
export const UNSET_STATES               = "unsetStates";
export const SET_RELIGION               = "setReligion";
export const UNSET_RELIGION             = "unsetReligion";
export const SET_RELATIONS              = "setRelations";
export const UNSET_RELATIONS            = "unsetRelations";
export const SET_MARITAL_STATUS         = "setMaritalStatus";
export const UNSET_MARITAL_STATUS       = "unsetMaritalStatus";
export const SET_EDUCATION_LEVEL        = "setEducationLevel";
export const UNSET_EDUCATION_LEVEL      = "unsetEducationLevel";
export const SET_HOUSE_OWNERSHIP        = "setHouseOwnership";
export const UNSET_HOUSE_OWNERSHIP      = "unsetHouseOwnership";
export const SET_EMPLOYER_TYPE_LIST     = "setEmployerTypes";
export const UNSET_EMPLOYER_TYPE_LIST   = "unsetEmployerTypes";
export const SET_OCCUPATION_TYPE_LIST   = "setOccupationTypes";
export const UNSET_OCCUPATION_TYPE_LIST = "unsetOccupationTypes";
export const PURGE_LOOKUPS              = "purgeLookups";
// export const SET_LOOKUPS                = "setLookups";


const state = {
  city: [],
  race: [],
  banks: [],
  branch: [],
  gender: [],
  salute: [],
  states: [],
  religion: [],
  relations: [],
  employerTypes: [],
  maritalStatus: [],
  educationLevel: [],
  houseOwnership: [],
  occupationTypes: [],
};

const getters = {
  employerTypes(state) {
    return state.employerTypes;
  },
};

const actions = {
  // async [GET_EMPLOYER_TYPE_LIST](context, credentials) {
  //   return await ApiService.get("/colist");
  // },

  async [GET_LOOKUPS](context) {
    var _ret = null;
    const apiRoute = "/lookups";

    await ApiService.get(apiRoute)
      .then((resp) => {
        //nfh-console.log(`[${apiRoute}] GET_LOOKUPS <fetched>`, resp);

        const _res = makeDataReadable(resp?.data);
        //nfh-console.log(_res);

        if (_res?.success) {
          context.commit(SET_CITY, _res?.data?.city);
          context.commit(SET_RACE, _res?.data?.race);
          context.commit(SET_BANKS, _res?.data?.banks);
          context.commit(SET_GENDER, _res?.data?.gender);
          context.commit(SET_SALUTE, _res?.data?.salute);
          context.commit(SET_STATES, _res?.data?.state);
          context.commit(SET_RELIGION, _res?.data?.religion);
          context.commit(SET_RELATIONS, _res?.data?.relationship);
          context.commit(SET_MARITAL_STATUS, _res?.data?.marital_status);
          context.commit(SET_EDUCATION_LEVEL, _res?.data?.education_level);
          context.commit(SET_HOUSE_OWNERSHIP, _res?.data?.house_ownership);
          context.commit(SET_EMPLOYER_TYPE_LIST, _res?.data?.employer_type);
          context.commit(SET_OCCUPATION_TYPE_LIST, _res?.data?.occupation_type);
        }
        else {
          //nfh-console.log(`[${apiRoute}] GET_LOOKUPS <FAILED>`, _res);
        }
      })
      .catch(err => {
        //nfh-console.log(`[${apiRoute}] GET_LOOKUPS <CATCH-ERR>`, err);
      });

    if (!_ret) {
      //nfh-console.log(`[${apiRoute}] GET_LOOKUPS <NULLED>`);
    }
  },
};

const mutations = {
  [SET_CITY](state, city) {
    state.city = city;
    localStorage.setItem("cty", JSON.stringify(state.city));
  },

  [UNSET_CITY](state) {
    state.city = [];
    localStorage.removeItem("cty");
  },

  [SET_RACE](state, race) {
    state.race = race;
    localStorage.setItem("rce", JSON.stringify(state.race));
  },

  [UNSET_RACE](state) {
    state.race = [];
    localStorage.removeItem("rce");
  },

  [SET_BANKS](state, banks) {
    state.banks = banks;
    localStorage.setItem("bnk", JSON.stringify(state.banks));
  },

  [UNSET_BANKS](state) {
    state.banks = [];
    localStorage.removeItem("bnk");
  },

  [SET_GENDER](state, gender) {
    state.gender = gender;
    localStorage.setItem("gdr", JSON.stringify(state.gender));
  },

  [UNSET_GENDER](state) {
    state.gender = [];
    localStorage.removeItem("gdr");
  },

  [SET_SALUTE](state, salute) {
    state.salute = salute;
    localStorage.setItem("sal", JSON.stringify(state.salute));
  },

  [UNSET_SALUTE](state) {
    state.salute = [];
    localStorage.removeItem("sal");
  },

  [SET_STATES](state, states) {
    state.states = states;
    localStorage.setItem("stt", JSON.stringify(state.states));
  },

  [UNSET_STATES](state) {
    state.states = [];
    localStorage.removeItem("stt");
  },

  [SET_RELIGION](state, religion) {
    state.religion = religion;
    localStorage.setItem("rgn", JSON.stringify(state.religion));
  },

  [UNSET_RELIGION](state) {
    state.religion = [];
    localStorage.removeItem("rgn");
  },

  [SET_RELATIONS](state, relations) {
    state.relations = relations;
    localStorage.setItem("rel", JSON.stringify(state.relations));
  },

  [UNSET_RELATIONS](state) {
    state.relations = [];
    localStorage.removeItem("rel");
  },

  [SET_EMPLOYER_TYPE_LIST](state, employerTypes) {
    state.employerTypes = employerTypes;
    localStorage.setItem("emp", JSON.stringify(state.employerTypes));
  },

  [UNSET_EMPLOYER_TYPE_LIST](state) {
    state.employerTypes = [];
    localStorage.removeItem("emp");
  },

  [SET_MARITAL_STATUS](state, maritalStatus) {
    state.maritalStatus = maritalStatus;
    localStorage.setItem("mry", JSON.stringify(state.maritalStatus));
  },

  [UNSET_MARITAL_STATUS](state) {
    state.maritalStatus = [];
    localStorage.removeItem("mry");
  },

  [SET_EDUCATION_LEVEL](state, educationLevel) {
    state.educationLevel = educationLevel;
    localStorage.setItem("edu", JSON.stringify(state.educationLevel));
  },

  [UNSET_EDUCATION_LEVEL](state) {
    state.educationLevel = [];
    localStorage.removeItem("edu");
  },

  [SET_HOUSE_OWNERSHIP](state, houseOwnership) {
    state.houseOwnership = houseOwnership;
    localStorage.setItem("own", JSON.stringify(state.houseOwnership));
  },

  [UNSET_HOUSE_OWNERSHIP](state) {
    state.houseOwnership = [];
    localStorage.removeItem("own");
  },

  [SET_OCCUPATION_TYPE_LIST](state, occupationTypes) {
    state.occupationTypes = occupationTypes;
    localStorage.setItem("occ", JSON.stringify(state.occupationTypes));
  },

  [UNSET_OCCUPATION_TYPE_LIST](state) {
    state.occupationTypes = [];
    localStorage.removeItem("occ");
  },

  async [PURGE_LOOKUPS](state) {
    state.city            = [];
    state.race            = [];
    state.banks           = [];
    state.gender          = [];
    state.salute          = [];
    state.states          = [];
    state.religion        = [];
    state.relations       = [];
    state.employerTypes   = [];
    state.maritalStatus   = [];
    state.educationLevel  = [];
    state.houseOwnership  = [];
    state.occupationTypes = [];

    localStorage.removeItem("cty");
    localStorage.removeItem("rce");
    localStorage.removeItem("bnk");
    localStorage.removeItem("gdr");
    localStorage.removeItem("sal");
    localStorage.removeItem("stt");
    localStorage.removeItem("rgn");
    localStorage.removeItem("rel");
    localStorage.removeItem("emp");
    localStorage.removeItem("mry");
    localStorage.removeItem("edu");
    localStorage.removeItem("own");
    localStorage.removeItem("occ");

    // context.commit(UNSET_CITY);
    // context.commit(UNSET_RACE);
    // context.commit(UNSET_BANKS);
    // context.commit(UNSET_GENDER);
    // context.commit(UNSET_SALUTE);
    // context.commit(UNSET_STATES);
    // context.commit(UNSET_RELIGION);
    // context.commit(UNSET_RELATIONS);
    // context.commit(UNSET_MARITAL_STATUS);
    // context.commit(UNSET_EDUCATION_LEVEL);
    // context.commit(UNSET_HOUSE_OWNERSHIP);
    // context.commit(UNSET_EMPLOYER_TYPE_LIST);
    // context.commit(UNSET_OCCUPATION_TYPE_LIST);
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
