const path = require("path");
const BundleAnalyzerPlugin = require("webpack-bundle-analyzer").BundleAnalyzerPlugin;

// const UglifyJsPlugin = require('uglifyjs-webpack-plugin');
// const isProd = process.env.NODE_ENV === "production";

module.exports = {
  devServer: {
    disableHostCheck: true,
    port: 4000,
    public: "localhost:4000",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*"
    }
  },
  productionSourceMap: false,
  publicPath: process.env.NODE_ENV === "production" ? "./" : "./",
  configureWebpack: {
    devtool: "source-map",
    resolve: {
      alias: {
        // If using the runtime only build
        // vue$: "vue/dist/vue.runtime.esm.js" // 'vue/dist/vue.runtime.common.js' for webpack 1
        // Or if using full build of Vue (runtime + compiler)
        // vue$: 'vue/dist/vue.esm.js'      // 'vue/dist/vue.common.js' for webpack 1
        vue$:
          process.env.NODE_ENV === "production"
            ? "vue/dist/vue.runtime.esm.js" //"vue/dist/vue.esm.js"
            : "vue/dist/vue.esm.js"
      }
    },
    plugins: [
      new BundleAnalyzerPlugin({
          analyzerMode: "disabled",
          generateStatsFile: false,
        }
      )
    ],
    // optimization: {
    //   minimizer: true ? [
    //     new UglifyJsPlugin({
    //       uglifyOptions: {
    //         compress: {
    //           drop_console: true
    //         },
    //       }
    //     })
    //   ] : []
    // }
  },
  chainWebpack: config => {
    config.module
      .rule("eslint")
      .use("eslint-loader")
      .tap(options => {
        options.configFile = path.resolve(__dirname, ".eslintrc.js");
        return options;
      });
  },
  css: {
    loaderOptions: {
      postcss: {
        config: {
          path: __dirname
        }
      },
      scss: {
        prependData: `@import "@/assets/sass/vendors/vue/vuetify/variables.scss";`
      }
    }
  },
  transpileDependencies: ["vuetify"]
};
