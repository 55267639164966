import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import axios      from "axios";

// action types
export const GET_APPROVED_APPLICATIONS         = "getApprovedApplications";
export const GET_PENDING_APPLICATION_BATCHES   = "getPendingApplicationBatches";
export const GET_PENDING_APPLICATIONS_BY_BATCH = "getPendingApplicationsByBatch";
export const GET_APPROVED_APPLICATION_BY_BATCH = "getApprovedApplicationByBatch";
export const GET_KIV_APPLICATION_BY_BATCH      = "getKIVApplicationByBatch";
export const GET_REJECTED_APPLICATION_BY_BATCH = "getRejectedApplicationByBatch";
export const APPROVER_REJECT_APPLICATION       = "approverRejectApplication";
export const APPROVE_APPLICATION               = "approvedApplication";
export const KIV_APPLICATION                   = "kivApplication";

// mutation types
export const SET_APPROVED_APPLICATIONS         = "setApprovedApplications";
export const SET_PENDING_APPLICATION_BATCHES   = "setPendingApplicationBatches";
export const SET_PENDING_APPLICATIONS          = "setPendingApplications";
export const SET_KIV_APPLICATIONS              = "setKIVApplications";
export const SET_REJECTED_APPLICATIONS         = "setRejectedApplications";
export const UNSET_APPROVED_APPLICATIONS       = "unsetApprovedApplications";
export const UNSET_PENDING_APPLICATION_BATCHES = "unsetPendingApplicationBatches";
export const UNSET_PENDING_APPLICATIONS        = "unsetPendingApplications";
export const UNSET_KIV_APPLICATIONS            = "unsetKIVApplications";
export const UNSET_REJECTED_APPLICATIONS       = "unsetRejectedApplications";
export const PURGE_ALL_APPROVABLE_APPS         = "purgeAllApprovableApps";

const state = {
    batches: {},

    apprApplications: {
        data: {},
        errcode: "",
        message: "",
        success: false,
    },

    pendingApplications: {
        data: {},
        errcode: "",
        message: "",
        success: false,
    },

    kivApplications: {
        data: {},
        errcode: "",
        message: "",
        success: false,
    },

    rejectedApplications: {
        data: {},
        errcode: "",
        message: "",
        success: false,
    },
};

const getters = {
    apprApplications(state) {
        return state.apprApplications;
    },

    pendingApplicationBatches(state) {
        return state.batches;
    },

    pendingApplications(state) {
        return state.pendingApplications;
    },

    kivApplications(state) {
        return state.kivApplications;
    },
    rejectedApplications(state) {
        return state.rejectedApplications;
    },
};

const actions = {
    async [GET_PENDING_APPLICATION_BATCHES](context, credentials) {
        if (JwtService.getToken()) {
            ApiService.setHeader();

            return ApiService.get("/appr/pending");
        }
    },

    async [GET_PENDING_APPLICATIONS_BY_BATCH](context, credentials) {
        if (JwtService.getToken()) {
            ApiService.setHeader();

            return axios.get(
                "/appr/pending?batch=" + credentials?.batch_no +
                "&detail="             + credentials?.detail
            );
        }
    },

    async [GET_APPROVED_APPLICATION_BY_BATCH](context, credentials) {
        if (JwtService.getToken()) {
            ApiService.setHeader();

            return axios.get(
                "/appr/pending?batch=" + credentials?.batch_no +
                "&detail="             + credentials?.detail   +
                "&approved="           + credentials?.approved
            );
        }
    },

    async [GET_KIV_APPLICATION_BY_BATCH](context, credentials) {
        if (JwtService.getToken()) {
            ApiService.setHeader();

            return axios.get(
                "/appr/pending?batch=" + credentials?.batch_no +
                "&detail="             + credentials?.detail   +
                "&approved="           + credentials?.approved +
                "&kiv="                + credentials?.kiv
            );
        }
    },

    async [GET_REJECTED_APPLICATION_BY_BATCH](context, credentials) {
        if (JwtService.getToken()) {
            ApiService.setHeader();

            return axios.get(
                "/appr/pending?batch=" + credentials?.batch_no +
                "&detail="             + credentials?.detail   +
                "&approved="           + credentials?.approved +
                "&kiv="                + credentials?.kiv      +
                "&rejected="           + credentials?.rejected
            );
        }
    },

    async [APPROVE_APPLICATION](context, credentials) {
        if (JwtService.getToken()) {
            ApiService.setHeader();

            return ApiService.post("/appr/" + credentials?.application_id);
        }
    },

    async [KIV_APPLICATION](context, credentials) {
        if (JwtService.getToken()) {
            ApiService.setHeader();

            return ApiService.post("/appr/kiv/" + credentials?.application_id);
        }
    },

    async [APPROVER_REJECT_APPLICATION](context, credentials) {
        if (JwtService.getToken()) {
            ApiService.setHeader();

            return ApiService.post("/appr/reject/" + credentials?.application_id);
        }
    },
};

const mutations = {
    [SET_PENDING_APPLICATION_BATCHES](state, pendingApplicationBatches) {
        state.batches = pendingApplicationBatches;
        //nfh-console.log('[STATE.approvedapplication.module] batches <SET>', state.batches);
    },

    [SET_PENDING_APPLICATIONS](state, pendingApplications) {
        state.pendingApplications = pendingApplications;
        //nfh-console.log('[STATE.approvedapplication.module] pendingApplications <SET>', state.pendingApplications);
    },

    [SET_APPROVED_APPLICATIONS](state, apprApplications) {
        state.apprApplications = apprApplications;
        //nfh-console.log('[STATE.approvedapplication.module] apprApplications <SET>', state.apprApplications);
    },

    [SET_KIV_APPLICATIONS](state, kivApplications) {
        state.kivApplications = kivApplications;
        //nfh-console.log('[STATE.approvedapplication.module] kivApplications <SET>', state.kivApplications);
    },

    [SET_REJECTED_APPLICATIONS](state, rejectedApplications) {
        state.rejectedApplications = rejectedApplications;
        //nfh-console.log('[STATE.approvedapplication.module] rejectedApplications <SET>', state.rejectedApplications);
    },

    [UNSET_PENDING_APPLICATION_BATCHES](state) {
        state.batches = {};
        //nfh-console.log('[STATE.approvedapplication.module] batches <UNSET>', state.batches);
    },

    [UNSET_PENDING_APPLICATIONS](state) {
        state.pendingApplications = {
            data: {},
            errcode: "",
            message: "",
            success: false,
        };
        //nfh-console.log('[STATE.approvedapplication.module] pendingApplications <UNSET>', state.pendingApplications);
    },

    [UNSET_APPROVED_APPLICATIONS](state) {
        state.apprApplications = {
            data: {},
            errcode: "",
            message: "",
            success: false,
        };
        //nfh-console.log('[STATE.approvedapplication.module] apprApplications <UNSET>', state.apprApplications);
    },

    [UNSET_KIV_APPLICATIONS](state) {
        state.kivApplications = {
            data: {},
            errcode: "",
            message: "",
            success: false,
        };
        //nfh-console.log('[STATE.approvedapplication.module] kivApplications <UNSET>', state.kivApplications);
    },

    [UNSET_REJECTED_APPLICATIONS](state) {
        state.rejectedApplications = {
            data: {},
            errcode: "",
            message: "",
            success: false,
        };
        //nfh-console.log('[STATE.approvedapplication.module] rejectedApplications <UNSET>', state.rejectedApplications);
    },

    [PURGE_ALL_APPROVABLE_APPS](state) {
        const _blankData = {
            data: {},
            errcode: "",
            message: "",
            success: false,
        };

        state.batches              = {};
        state.apprApplications     = Object.assign({}, _blankData);
        state.pendingApplications  = Object.assign({}, _blankData);
        state.kivApplications      = Object.assign({}, _blankData);
        state.rejectedApplications = Object.assign({}, _blankData);
        //nfh-console.log('[STATE.approvedapplication.module] All State <PURGE>', state);
    },
};

export default {
    state,
    actions,
    mutations,
    getters,
};

