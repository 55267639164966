import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
// import form from "@/core/services/store/form.module";
// import Vue from "vue";
// import axios from "axios";
// import VueAxios from "vue-axios";

// action types
export const CALCULATE       = "calculate";
export const CALCULATE_APP   = "calculateApp";
export const CALCULATE_GUEST = "calculateGuest";
export const CALCULATE_SAVE  = "calculateSave";
// export const GET_CALC = "getCalc";
// export const GET_COLIST = "getCompanyList";

// mutation types
export const SET_CALC_RESULT = "setCalcResult";
export const PURGE_ALL_CALC = "purgeAllCalc";
// export const SET_CALC = "setCalc";
// export const SET_COLIST = "setCompanyList";

const state = {
  calcResult: null,
};

const getters = {
  calculatedResult(state) {
    return state.calcResult;
  },
};

const actions = {
  async [CALCULATE](context, credentials) {
    const apiRoute = "/calc";
    var _ret = null;

    // if (JwtService.getToken()) {
    //   ApiService.setHeader();

      await ApiService.post(apiRoute, credentials)
        .then((resp) => {
          const _res = resp?.data;

          if (_res?.success && _res?.sch) {
            //nfh-console.log(`[${apiRoute}] CALCULATE <fetched>`, _res);
            context.commit(SET_CALC_RESULT, _res);
            _ret = _res;
          }
          else {
            //nfh-console.log(`[${apiRoute}] CALCULATE <FAILED>`, resp);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] CALCULATE <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] CALCULATE <NULLED>`);
      }
    // }
    // else {
    //   console.log(`[${apiRoute}] CALCULATE <getToken FAILED>`);
    // }

    return _ret;
  },

  async [CALCULATE_APP](context, credentials) {
    const apiRoute = "/calc_app";
    var _ret = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      await ApiService.post(apiRoute, credentials)
        .then((resp) => {
          const _res = resp?.data;

          if (_res?.success && _res?.sch) {
            //nfh-console.log(`[${apiRoute}] CALCULATE_APP <fetched>`, _res);
            context.commit(SET_CALC_RESULT, _res);
            _ret = _res;
          }
          else {
            //nfh-console.log(`[${apiRoute}] CALCULATE_APP <FAILED>`, resp);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] CALCULATE_APP <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] CALCULATE_APP <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] CALCULATE_APP <getToken FAILED>`);
    }

    return _ret;
  },

  async [CALCULATE_GUEST](context, credentials) {
    const apiRoute = "/calc_form";
    var _ret = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      await ApiService.post(apiRoute, credentials)
        .then((resp) => {
          const _res = resp?.data;

          if (_res?.success && _res?.sch) {
            //nfh-console.log(`[${apiRoute}] CALCULATE_GUEST <fetched>`, _res);
            context.commit(SET_CALC_RESULT, _res);
            _ret = _res;
          }
          else {
            //nfh-console.log(`[${apiRoute}] CALCULATE_GUEST <FAILED>`, resp);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] CALCULATE_GUEST <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] CALCULATE_GUEST <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] CALCULATE_GUEST <getToken FAILED>`);
    }

    return _ret;
  },

  async [CALCULATE_SAVE](context, credentials) {
    const apiRoute = "/calc_save";
    var _ret = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      await ApiService.post(apiRoute, credentials)
        .then((resp) => {
          const _res = resp?.data;

          if (_res?.success) {
            //nfh-console.log(`[${apiRoute}] CALCULATE_SAVE <fetched>`, _res);
            _ret = _res;
          }
          else {
            //nfh-console.log(`[${apiRoute}] CALCULATE_SAVE <FAILED>`, resp);
          }
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] CALCULATE_SAVE <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] CALCULATE_SAVE <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] CALCULATE_SAVE <getToken FAILED>`);
    }

    return _ret;
  },

  // async [GET_CALC](context, params) {
  //   ApiService.setHeader();
  //   await ApiService.post("/calc", params).then(async (res) => {
  //     await context.commit(SET_CALC, res.data.data);
  //     return res.data.data;
  //   });
  // },
  // async [GET_COLIST](context, params) {
  //   ApiService.setHeader();
  //   await ApiService.get("/colist").then(async (res) => {
  //     await context.commit(SET_COLIST, res.data.data);
  //     return res.data.data;
  //   });
  // },
};

const mutations = {
  [SET_CALC_RESULT](state, result) {
    state.calcResult = result;
    //nfh-console.log('[STATE.calculator.module] calculatedResult <set>', state.calcResult);
  },

  [PURGE_ALL_CALC](state, result) {
    state.calcResult = null;
    //nfh-console.log('[STATE.calculator.module] calculatedResult <unset>', state.calcResult);
  },
  // [SET_CALC](state, calc) {
  //   state.data.calc = calc;
  // },
  // [SET_COLIST](state, data) {
  //   state.data.colist = data;
  // },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
