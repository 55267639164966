<template>
  <v-dialog
    v-model="displayMessageDialog"
    max-width="400"
    min-height="200"
    :persistent="modal"
  >
    <v-card
    >
      <v-toolbar
        v-if="isTypeError == true"
        height="48"
        elevation="1"
        color="red darken-4"
        style="font-size: 12pt;"
        dark
      >
        {{ title }}
      </v-toolbar>

      <v-toolbar
        v-else
        height="48"
        elevation="1"
        color="#3367D6"
        style="font-size: 11pt;"
        dark
      >
        {{ title }}
      </v-toolbar>

      <v-card-text
        class="mt-5"
        replace="true"
        style="font-size: 10pt; text-align: justify;"
        v-html="content"
      ></v-card-text>
    <!-- {{ content }} -->

      <v-spacer
      ></v-spacer>

      <v-divider
        class="mt-0 mb-0"
        light
      ></v-divider>

      <v-card-actions
      >
        <v-spacer
        ></v-spacer>

        <v-btn
          v-if="showBackButton !== false"
          :loading="isBacking"
          text
          @click="onBacking()"
        >
          {{ backButton }}
        </v-btn>

        <v-btn
          v-if="showProceedButton !== false && isTypeError == true"
          color="white--text text--lighten-1 red darken-2"
          :loading="isProceeding"
          @click="onProceed()"
        >
          {{ proceedButton }}
        </v-btn>

        <v-btn
          v-if="showProceedButton !== false && isTypeError == false"
          color="primary"
          :loading="isProceeding"
          @click="onProceed()"
        >
          {{ proceedButton }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: "Info",
    },

    modal: {
      type: Boolean,
      default: false,
    },

    content: {
      type: String,
      default: "",
    },

    proceedButton: {
      type: String,
      default: "Proceed",
    },

    backButton: {
      type: String,
      default: "Back",
    },

    showProceedButton: {
      type: Boolean,
      default: false,
    },

    showBackButton: {
      type: Boolean,
      default: false,
    },

    delayOnProceed: {
      type: Boolean,
      default: true,
    },

    proceedDelaySec: {
      type: Number,
      default: 2,
    },

    isTypeError: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

  },

  data() {
    return {
      isProceeding: false,
      isBacking: false,
    };
  },

  computed: {
    displayMessageDialog: {
      get() {
        return this.value;
      },

      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },

  methods: {
    closeMessageDialog() {
      this.displayMessageDialog = false;
    },

    onBacking() {
      this.isBacking = true;
      setTimeout(() => {
        this.isBacking = false;
        this.closeMessageDialog();
        this.$emit("onBacking");
      }, ((this.delayOnProceed) ? this.proceedDelaySec * 1000 : 0));
    },

    onProceed() {
      this.isProceeding = true;
      setTimeout(() => {
        this.isProceeding = false;
        this.closeMessageDialog();
        this.$emit("onProceed");
      }, ((this.delayOnProceed) ? this.proceedDelaySec * 1000 : 0));
    },
  },

  mounted() {
  },
};
</script>

<style scoped></style>
