import axios from "axios";
import dayjs from "dayjs";
import isLeapYear from "dayjs/plugin/isLeapYear";
import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// import { SECURED_FILE } from "@/core/services/store/file.module";
import {
  SET_CHECKLIST_STAT,
  SET_CHECKLIST_DATA,
  SET_CHECKLIST_DOWNLOAD_DATA,
} from "@/core/services/store/mediaupload.module";

import {
  SET_EMPLOYMENT_FORM_SESSION,
  UPDATE_MEMBER_SESSION2,
} from "@/core/services/store/form.module";

dayjs.extend(isLeapYear);

const SECURED_FILE = "securedFile";

const VALID_STATE_CODES = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
  16, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32,
  34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46,
  47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59
];

const MONTHS_WITH_DAYS30 = [ 4, 6, 9, 11 ];
const MONTHS_WITH_DAYS31 = [ 1, 3, 5, 7, 8, 10, 12 ];
const MAX_DAYS = [ 0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];

const gender = [
  { "id": 1, "name": "MALE", "nameMS": "LELAKI", "value": "M", "valueMS": "L" },
  { "id": 2, "name": "FEMALE", "nameMS": "PEREMPUAN", "value": "F", "valueMS": "P" }
];

/**
 * Get confirmation whether the variable is an Object type {} or not.
 * @param _var
 * @returns {boolean}
 */
export const isMapObject = _var => (typeof _var === 'object' && _var !== null && !Array.isArray(_var)) ? true : false;

/**
 * Recursively convert all values of string elements in object to upper case value
 * except string attributes having named as 'email' and 'token'.
 * @param _obj
 */
export const convUpperCase = (_obj, _except = []) => {
  if (isMapObject(_obj)) {
    Object.entries(_obj).forEach(_ => {
      if (isMapObject(_[1])) {
        convUpperCase(_[1]);
      } else if (typeof _[1] === "string" && !_except.includes(_[0])) {
        _obj[_[0]] = _[1].toUpperCase();
      }
    });
  }
};

export const blankUser_old = () => {
  return {
    // identification
    nric: "",
    nric_display: "",
    phone_num: "",
    phone_num_display: "",
    email: "",
    // personal details
    full_name: "",
    birth_date: "",
    gender_id: "",
    race_id: "",
    religion_id: "",
    marital_status_id: "",
    salute_id: "",
    education_level_id: "",
    children: "",
    mother_name: "",
    // Bank info
    bank_name: "",
    bank_acc: "",
    banks_id: "",
    // address
    home_unit: "",
    home_street: "",
    home_line3: "",
    home_line4: "",
    home_postcode: "",
    home_city_id: "",
    home_state_id: "",
    home_ownership_id: "",
    // mailingAddress
    mailing_same_home: false,
    mailing_unit: "",
    mailing_street: "",
    mailing_line3: "",
    mailing_line4: "",
    mailing_postcode: "",
    mailing_city_id: "",
    mailing_state_id: "",
    mailing_ownership_id: "",
    // contacts
    home_num: "",
    user_office_num: "",
    // others
    financing_req_tenure: 0,
    financing_req_rate: 0,
    financing_req_inst: 0,
    financing_req_amount: 0,
    financing_req_deds: 0,
    signature: null,
    bankInfo: {},
  };
};

export const blankUser = () => {
  return {
    nric: null,
    nric_display: null,
    phone_num: null,
    phone_num_display: null,
    email: null,
    full_name: null,
    short_name: null,
    member_no: null,
    reg_nric: null,
    old_nric: null,
    dob: null,
    date_register: null,
    date_approval: null,
    mother_name: null,
    race_id: null,
    branch_id: null,
    gender_id: null,
    salute_id: null,
    agent_id: null,
    department_id: null,
    religion_id: null,
    marital_status_id: null,
    membership_status_id: null,
    access_category_id: null,
  };
};

export const blankFormSession = () => {
  return {
    step1: {
      id: null,
      user_id: null,
      nric: null,
      old_ic: null,
      phone_num: null,
      email: null,
      full_name: null,
      birth_date: null,
      gender_id: null,
      race_id: null,
      religion_id: null,
      marital_status_id: null,
      salute_id: null,
      education_level_id: null,
      children: null,
      mother_name: null,
      home_unit: null,
      home_street: null,
      home_line3: null,
      home_line4: null,
      home_postcode: null,
      home_city_id: null,
      home_state_id: null,
      home_ownership_id: null,
      bank_name: null,
      bank_acc: null,
      banks_id: null,
      mailing_same_home: false,
      mailing_unit: null,
      mailing_street: null,
      mailing_line3: null,
      mailing_line4: null,
      mailing_postcode: null,
      mailing_city_id: null,
      mailing_state_id: null,
      mailing_ownership_id: null,
      home_num: null,
      user_office_num: null,
      financing_req_amount: 0,
      financing_req_tenure: 0,
      financing_req_rate: 0,
      financing_req_inst: 0,
      financing_req_deds: 0,
      financing_calc: null,
    },
    //
    step2: {
      employer_name: null,
      employer_email: null,
      occupation: null,
      occupation_id: null,
      department: null,
      office_unit: null,
      office_street: null,
      office_line3: null,
      office_line4: null,
      office_postcode: null,
      office_city_id: null,
      office_state_id: null,
      office_num: null,
      office_fax: null,
      salary: 0,
      commencement_date: null,
      year_of_service: null,
    },
    //
    step3: {
      heir_name: null,
      heir_nric: null,
      heir_birth_date: null,
      heir_gender_id: null,
      heir_race_id: null,
      heir_religion_id: null,
      heir_unit: null,
      heir_street: null,
      heir_line3: null,
      heir_line4: null,
      heir_postcode: null,
      heir_city_id: null,
      heir_state_id: null,
      relationship_id: null,
      heir_phone_num: null,
      heir_home_num: null,
      heir_office_num: null,
      signature: null,
    },
  };
};

export const blankMember = () => {
  return {
    application_id: null,
    nric: null,
    phone_num: null,
    email: null,
    full_name: null,
    birth_date: null,
    gender_id: null,
    race_id: null,
    religion_id: null,
    marital_status_id: null,
    salute_id: null,
    education_level_id: null,
    children: null,
    mother_name: null,
    home_unit: null,
    home_street: null,
    home_line3: null,
    home_line4: null,
    home_postcode: null,
    home_city_id: null,
    home_state_id: null,
    home_ownership_id: null,
    bank_name: null,
    bank_acc: null,
    banks_id: null,
    mailing_same_home: false,
    mailing_unit: null,
    mailing_street: null,
    mailing_line3: null,
    mailing_line4: null,
    mailing_postcode: null,
    mailing_city_id: null,
    mailing_state_id: null,
    mailing_ownership_id: null,
    home_num: null,
    office_num: null,
    signature: null,
    agree_tnc: null,
    financing_req_amount: null,
    financing_req_tenure: null,
    financing_req_rate: null,
    financing_req_inst: null,
    financing_req_deds: null,
  };
};

export const blankEmployment = () => {
  return {
    application_id: null,
    employer_name: null,
    employer_email: null,
    employer_type_id: null,
    occupation: null,
    occupation_type_id: null,
    department: null,
    office_unit: null,
    office_street: null,
    office_line3: null,
    office_line4: null,
    office_postcode: null,
    office_city_id: null,
    office_state_id: null,
    office_num: null,
    office_num_ext: null,
    office_fax: null,
    salary: 0,
    commencement_date: null,
    resign_date: null,
    year_of_service: 0
  };
};

export const blankHeir = () => {
  return {
    application_id: null,
    heir_nric: null,
    heir_name: null,
    heir_birth_date: null,
    heir_gender_id: null,
    heir_race_id: null,
    heir_religion_id: null,
    heir_unit: null,
    heir_street: null,
    heir_line3: null,
    heir_line4: null,
    heir_postcode: null,
    heir_city_id: null,
    heir_state_id: null,
    relationship_id: null,
    marital_status_id: null,
    heir_home_num: null,
    heir_phone_num: null,
    heir_office_num: null,
  };
};

export const blankDSR = () => {
  return {
    dsr_rate: 0,
    ex_ai_total: 0,
    ex_aii_total: 0,
    ex_a_total: 0,
    in_b_total: 0,
    in_c_total: 0,
    ex_ai1_house1: 0,
    ex_ai2_house2: 0,
    ex_ai3_car1: 0,
    ex_ai4_car2: 0,
    ex_ai5_kop1: 0,
    ex_ai6_kop2: 0,
    ex_ai7a_name: "",
    ex_ai7a_amt: 0,
    ex_ai7b_name: "",
    ex_ai7b_amt: 0,
    ex_ai7c_name: "",
    ex_ai7c_amt: 0,
    ex_aii1_house1: 0,
    ex_aii2_house2: 0,
    ex_aii3_car1: 0,
    ex_aii4_car2: 0,
    ex_aii5_kop1: 0,
    ex_aii6_kop2: 0,
    ex_aii7a_name: "",
    ex_aii7a_amt: 0,
    ex_aii7b_name: "",
    ex_aii7b_amt: 0,
    ex_aii7c_name: "",
    ex_aii7c_amt: 0,
    ex_aiii_inst_new: 0,
    in_b1_sal_basic: 0,
    in_b2a_allfixed_name: "",
    in_b2a_allfixed_amt: 0,
    in_b2b_allfixed_name: "",
    in_b2b_allfixed_amt: 0,
    in_b2c_allfixed_name: "",
    in_b2c_allfixed_amt: 0,
    in_b2d_allfixed_name: "",
    in_b2d_allfixed_amt: 0,
    in_b2e_allfixed_name: "",
    in_b2e_allfixed_amt: 0,
    in_b3a_allother_name: "",
    in_b3a_allother_amt: 0,
    in_b3b_allother_name: "",
    in_b3b_allother_amt: 0,
    in_b3c_allother_name: "",
    in_b3c_allother_amt: 0,
    in_b3d_allother_name: "",
    in_b3d_allother_amt: 0,
    in_b3e_allother_name: "",
    in_b3e_allother_amt: 0,
    in_b4a_income_name: "",
    in_b4a_income_amt: 0,
    in_b4b_income_name: "",
    in_b4b_income_amt: 0,
    in_b4c_income_name: "",
    in_b4c_income_amt: 0,
    in_b4d_income_name: "",
    in_b4d_income_amt: 0,
    in_b4e_income_name: "",
    in_b4e_income_amt: 0,
    in_c1_epf: 0,
    in_c2_socso: 0,
    in_c3_pcb: 0,
    in_c4_zakat: 0,
    in_c5a_name: "",
    in_c5a_amt: 0,
    in_c5b_name: "",
    in_c5b_amt: 0,
    in_c5c_name: "",
    in_c5c_amt: 0,
  };
};

export const calcDSR = (dsr) => {
  try {
    var _d = Object.assign(blankDSR(), dsr);
    dsr.ex_ai_total  = parseFloat(dsr.ex_ai1_house1) + parseFloat(dsr.ex_ai2_house2) +
                       parseFloat(dsr.ex_ai3_car1) + parseFloat(dsr.ex_ai4_car2) +
                       parseFloat(dsr.ex_ai5_kop1) + parseFloat(dsr.ex_ai6_kop2) +
                       parseFloat(dsr.ex_ai7a_amt) + parseFloat(dsr.ex_ai7b_amt) + parseFloat(dsr.ex_ai7c_amt);
    dsr.ex_aii_total = parseFloat(dsr.ex_aii1_house1) + parseFloat(dsr.ex_aii2_house2) +
                       parseFloat(dsr.ex_aii3_car1) + parseFloat(dsr.ex_aii4_car2) +
                       parseFloat(dsr.ex_aii5_kop1) + parseFloat(dsr.ex_aii6_kop2) +
                       parseFloat(dsr.ex_aii7a_amt) + parseFloat(dsr.ex_aii7b_amt) + parseFloat(dsr.ex_aii7c_amt);
    dsr.ex_a_total   = parseFloat(dsr.ex_ai_total) + parseFloat(dsr.ex_aii_total) + parseFloat(dsr.ex_aiii_inst_new);
    dsr.in_b_total   = parseFloat(dsr.in_b1_sal_basic) +
                       parseFloat(dsr.in_b2a_allfixed_amt) + parseFloat(dsr.in_b2b_allfixed_amt) + parseFloat(dsr.in_b2c_allfixed_amt) +
                       parseFloat(dsr.in_b2d_allfixed_amt) + parseFloat(dsr.in_b2e_allfixed_amt) +
                       parseFloat(dsr.in_b3a_allother_amt) + parseFloat(dsr.in_b3b_allother_amt) + parseFloat(dsr.in_b3c_allother_amt) +
                       parseFloat(dsr.in_b3d_allother_amt) + parseFloat(dsr.in_b3e_allother_amt) +
                       parseFloat(dsr.in_b4a_income_amt) + parseFloat(dsr.in_b4b_income_amt) + parseFloat(dsr.in_b4c_income_amt) +
                       parseFloat(dsr.in_b4d_income_amt) + parseFloat(dsr.in_b4e_income_amt);
    dsr.in_c_total   = parseFloat(dsr.in_c1_epf) + parseFloat(dsr.in_c2_socso) + parseFloat(dsr.in_c3_pcb) + parseFloat(dsr.in_c4_zakat) +
                       parseFloat(dsr.in_c5a_amt) + parseFloat(dsr.in_c5b_amt) + parseFloat(dsr.in_c5c_amt);

    var a = dsr.ex_a_total;
    var b = dsr.in_b_total;
    var c = dsr.in_c_total;

    dsr.dsr_rate = ((b - c) != 0) ? Math.fround((a / (b - c)) * 100) : (a == 0 ? 0 : a);
    return dsr;
  }
  catch(e) {
  }
};

const nric_dob = (nric) => {
  //nfh-console.log('nric_dob: ', nric);
  try {
    if (nric != '000000000000') {
      const el = nric.split('-');
      const y = dayjs().year();
      const ys = y.toString();
      const ys2 = ys.substring(0, 2);
      //nfh-console.log('nric_dob el:', el);
      if (el[0].length >= 6) {
        el[0] = ((parseInt(el[0].substring(0, 2)) < y - 17 - Math.floor(y / 100) * 100) ? ys2 : (parseInt(ys2) - 1).toString()).toString() + el[0];
        // const _ret = (el[0].length >= 8) ? dayjs(el[0].substring(0, 8), 'YYYYMMDD').format('YYYY-MM-DD HH:mm:ss') : null;
        const _ret = (el[0].length >= 8) ? dayjs(el[0].substring(0, 8), 'YYYYMMDD').format('YYYY-MM-DD') : null;
        //nfh-console.log('nric_dob _ret:', _ret);
        return _ret;
      }
    }
  }
  catch (e) {
    //nfh-console.log('nric_dob exception:', e);
  }
  return null;
};

const nric_gender = (nric) => {
  //nfh-console.log('nric_gender: ', nric);
  try {
    const el = nric.split('-');
    const sign = (el.length == 1 && el[0].length == 12)
                  ? parseInt(el[0].charAt(11))
                  : (
                      (el.length == 3 && el[2].length == 4)
                        ? parseInt(el[2].charAt(3))
                        : null
                    );
    const _ret = (sign != null && !isNaN(parseInt(sign)))
            ? sign % 2
            : null;
    //nfh-console.log('nric_gender: ', _ret)
    return _ret;
  } catch (e) { }
  return null;
};

export const formatNumber = (value) => {
  //nfh-console.log('formatNumber: ', value);
  value ??= 0.0;
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const formatAmount = (value) => {
  //nfh-console.log('formatAmount: ', value);
  value ??= 0.0;
  return 'RM ' + formatNumber(value);
};

export const formatPercentage = (value) => {
  //nfh-console.log('formatPercentage: ', value);
  value ??= 0.0;
  return formatNumber(Number(value).toFixed(2)) + ' %';
};

export const sanitize_nric = (nric) => {
  //nfh-console.log('sanitize_nric: ', nric);
  var _ret = nric;
  try {
    _ret = String(nric || '').trim().replace(/\D/g, '').padEnd(12, '0');
    return _ret.substring(Math.max(_ret.length - 12, 0));
  } catch (e) {}
  return _ret;
};

export const is_valid_nric = (nric, onlyRealNRIC) => {
  if (onlyRealNRIC == false) {
    return true;
  }
  else {
    var _nric    = sanitize_nric(nric);
    const y      = dayjs().year();
    const ys     = y.toString().substring(0, 2);
    let _yr      = 0;
    let _mt      = 0;
    let _dy      = 0;
    let _st      = 0;
    let yr_valid = false;
    let mt_valid = false;
    let dy_valid = false;
    let st_valid = false;

    try {
      _nric = nric.split('-');
      if (_nric.length == 1) {
        if (_nric[0].length >= 8) {
          _nric[0] = ((parseInt(_nric[0].substring(0, 2)) < y - 17 - Math.floor(y / 100) * 100) ? ys : (parseInt(ys) - 1).toString()).toString() + _nric[0];
          _yr      = parseInt(_nric[0].substr(0, 4));
          _mt      = parseInt(_nric[0].substr(4, 2));
          _dy      = parseInt(_nric[0].substr(6, 2));
          _st      = parseInt(_nric[0].substr(8, 2));
        }
      }
      else if (_nric.length >= 2) {
        if (_nric[0].length >= 6) {
          _nric[0] = ((parseInt(_nric[0].substring(0, 2)) < y - 17 - Math.floor(y / 100) * 100) ? ys : (parseInt(ys) - 1).toString()).toString() + _nric[0];
          _yr      = parseInt(_nric[0].substr(0, 4));
          _mt      = parseInt(_nric[0].substr(4, 2));
          _dy      = parseInt(_nric[0].substr(6, 2));
        }
        _st      = parseInt(_nric[1]);
      }

      yr_valid = !!(_yr < y - 17 && _yr > y - 17 - 100);
      mt_valid = !!(_mt >= 1 && _mt <= 12);
      dy_valid = !!(mt_valid ? _dy >= 1 && _dy <= (_mt == 2 ? (dayjs(`${_yr}-${_mt}-1`).isLeapYear() ? 29 : 28) : MAX_DAYS[_mt]) : false);
      st_valid = !!(VALID_STATE_CODES.includes(_st));
      return !!(yr_valid && mt_valid && dy_valid && st_valid);
    } catch (e) { }
  }

  return false;
};

export const extractInfoNRIC = (nric) => {
  nric = sanitize_nric(nric);
  //nfh-console.log('extractInfoNRIC nric', nric);
  var _ret = {
    birth_date: null,
    gender_id: null,
    salute_id: null,
  };

  if (nric && nric_dob(nric)) {
    _ret.birth_date = nric_dob(nric);
    const gdr = nric_gender(nric);

    gender.forEach((_) => {
      if (gdr == 1 && String(_.value)?.toUpperCase() == "M") {
        _ret.gender_id = _.id;
        _ret.salute_id = 1;
      }
      else if (gdr == 0 && String(_.value)?.toUpperCase() == "F") {
        _ret.gender_id = _.id;
      }
    });
  }

  return _ret;
};

/*
  Filter given array of object records <recs> based on <criteria> given of specific key:value to filter.

  <recs>     : Array of Object(s) having same set of keys
  <criteria> : Object contains key(s) we want the object in <recs> to be filtered with based on the value(s)
 */
export const filterObjects = (recs, criteria) => {
  if (Array.isArray(recs) && recs.length > 0 && isObject(recs[0]) && isObject(criteria)) {
    var _da = {
      sta: true,                 // status to show the record satify the criteria
      o: [],                     // output gathered satisfy the criteria
      key: Object.keys(criteria) // all the key(s) contained in criteria
    };
    recs.forEach(rec => {
      _da.sta = true; // initialized as true (satisfied criteria)
      _da.key.forEach(key => { // traverse each record(s)
        // consider as satisfy criteria when value is matching or value in record is null, and to be
        // multiplied with running <_da.sta> to ensure all filter(s) condition is met.
        _da.sta = !!(_da.sta && (rec[key] == criteria[key] || rec[key] == null));
      });
      if (_da.sta) _da.o.push(rec); // push current record to output array if status maintains true
    });
    return _da.o;
  }
  return recs;
};


export const ord = (string) => {
  // function ord(string) {
  //  discuss at: https://locutus.io/php/ord/
  // original by: Kevin van Zonneveld (https://kvz.io)
  // bugfixed by: Onno Marsman (https://twitter.com/onnomarsman)
  // improved by: Brett Zamir (https://brett-zamir.me)
  //    input by: incidence
  //   example 1: ord('K')
  //   returns 1: 75
  //   example 2: ord('\uD800\uDC00'); // surrogate pair to create a single Unicode character
  //   returns 2: 65536
  const str = string + ''
  const code = str.charCodeAt(0)
  if (code >= 0xD800 && code <= 0xDBFF) {
    // High surrogate (could change last hex to 0xDB7F to treat high private surrogates as single characters)
    const hi = code
    if (str.length === 1) {
      // This is just a high surrogate with no following low surrogate, so we return its value;
      return code
      // we could also throw an error as it is not a complete character, but someone may want to know
    }
    const low = str.charCodeAt(1)
    return ((hi - 0xD800) * 0x400) + (low - 0xDC00) + 0x10000
  }
  if (code >= 0xDC00 && code <= 0xDFFF) {
    // Low surrogate
    // This is just a low surrogate with no preceding high surrogate, so we return its value;
    return code
    // we could also throw an error as it is not a complete character, but someone may want to know
  }
  return code
}

export const errorMessages = (message) => {
  if (typeof message == 'string') {
    switch (String(message).toLowerCase()) {
      case "network error":
        return "Sistem tidak dapat mengakses server buat masa ini.\n\nSila pastikan internet anda berfungsi dengan baik dan cuba semula.";
      default:
        return message;
    }
  }
  else if (isMapObject(message)) {
    return JSON.stringify(message);
  }
  else {
    return JSON.stringify(message);
  }
}

export const promptError = (message) => {
  try {
    return errorMessages(message);
  }
  catch(err) {
    return null;
  }
}

export const salesOnlineValidation = async (tkn) => {
  return new Promise((resolve, reject) => {
    if (tkn) {
      ApiService.setHeaderByParam(tkn);
      axios.post('/user').then((resp) => {
        resolve(resp);
      })
    }
  })
}

export const getSecuredFile = async (source) => {
  return new Promise((resolve, reject) => {
    if (source) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        axios.get(source, { responseType: 'blob' })
        .then((resp) => {
          // xxconsole.info(resp);
          resolve(URL.createObjectURL(resp.data));
        })
        .catch((e) => {
          // xxconsole.error(e);
          reject("");
        });
      } else {
        // xxconsole.error(e);
        reject("");
      }
    } else {
      // xxconsole.error(e);
      reject("");
    }
  });
  // ===================================================================
  // Method 1: convert Blob to base64 string.
  //           This method requires converting the whole file into a base64
  //           string and load into the img/embed tag of src properties
  // ---------------------------------------
  // return new Promise((resolve, reject) => {
  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     resolve(reader.result);
  //   }
  //   reader.onerror = () => {
  //     reject;
  //   }
  //   reader.readAsDataURL(resp.data);
  // });
  // ===================================================================

  // ===================================================================
  // Method 2: Use URL Web API (https://developer.mozilla.org/en-US/docs/Web/API/URL)
  //           This method requires feeding Blob object of the file into the
  //           URL.createObjectURL function and it will return a string containing
  //           a URL representing the object given in the parameter.
  // ---------------------------------------
  // return URL.createObjectURL(resp.data);
  // ===================================================================
  //
  // async getFile() {
  //   try {
  //     console.log(this.src);
  //     const resp = await this.$store.dispatch(SECURED_FILE, this.src);
  //     // ===================================================================
  //     // Method 1: convert Blob to base64 string.
  //     //           This method requires converting the whole file into a base64
  //     //           string and load into the img/embed tag of src properties
  //     // ---------------------------------------
  //     // this.url = await new Promise((resolve, reject) => {
  //     //   const reader = new FileReader();
  //     //   reader.onloadend = () => {
  //     //     resolve(reader.result);
  //     //   }
  //     //   reader.onerror = () => {
  //     //     reject;
  //     //   }
  //     //   reader.readAsDataURL(resp.data);
  //     // });
  //     // ===================================================================
  //
  //     // ===================================================================
  //     // Method 2: Use URL Web API (https://developer.mozilla.org/en-US/docs/Web/API/URL)
  //     //           This method requires feeding Blob object of the file into the
  //     //           URL.createObjectURL function and it will return a string containing
  //     //           a URL representing the object given in the parameter.
  //     // ---------------------------------------
  //     this.url = URL.createObjectURL(resp.data);
  //     // ===================================================================
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }
}


export const formatAddress = (line1, line2, line3, line4, postcode, city, state) => {
  var _addr = [];
  var _tmp = [];
  var line5 = "";
  var cts = city ? JSON.parse(localStorage.getItem("cty")).find((e) => e.id == city).name : "";
  var line6 = state ? JSON.parse(localStorage.getItem("stt")).find((e) => e.id == state).name : "";
  if (postcode) { _tmp.push(String(postcode).toUpperCase()); }
  if (cts) { _tmp.push(String(cts).toUpperCase()); }
  line5 = _tmp.length > 0 ?_tmp.join(" ") : "";

  if (line1) { _addr.push(String(line1).toUpperCase()); }
  if (line2) { _addr.push(String(line2).toUpperCase()); }
  if (line3) { _addr.push(String(line3).toUpperCase()); }
  if (line4) { _addr.push(String(line4).toUpperCase()); }
  if (line5) { _addr.push(String(line5).toUpperCase()); }
  if (line6) { _addr.push(String(line6).toUpperCase()); }
  return _addr.length > 0 ? _addr.join("\n") : "";
}

export const emailFormatCheck = (email) => {
  var EMAIL_REGEX = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9][a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return EMAIL_REGEX.test(email);
}

export const extractPagingInfoOnly = (response) => {
  var _ret = response;

  if (isObject(response)) {
    if (
      response.hasOwnProperty('first_page_url') &&
      response.hasOwnProperty('next_page_url') &&
      response.hasOwnProperty('last_page_url') &&
      response.hasOwnProperty('current_page') &&
      response.hasOwnProperty('links') &&
      response.hasOwnProperty('from') &&
      response.hasOwnProperty('to') &&
      response.hasOwnProperty('total')
    ) {
      _ret = Object.assign({}, response);
      delete _ret.data;
    }
  }
  return _ret;
}

export const verifyPagedData = (response) => {
  var _ret = false;

  if (isObject(response)) {
    if (
      response.hasOwnProperty('data') &&
      response.hasOwnProperty('first_page_url') &&
      response.hasOwnProperty('next_page_url') &&
      response.hasOwnProperty('last_page_url') &&
      response.hasOwnProperty('current_page') &&
      response.hasOwnProperty('links') &&
      response.hasOwnProperty('total') &&
      response.hasOwnProperty('from') &&
      response.hasOwnProperty('to')
    ) {
      return (response.data.length > 0);
    }
  }
  return _ret;
}

export const generateChecklist = async (docList, masterId, isGuest) => {
  //nfh-console.log(' IN: generateChecklist');
  var checklistDownloadData = [];
  var checklistData = [];
  var _hasChecklist = false;
  var _chklistStat = {
    total: 0,
    filled: 0,
    remain: 0,
  };

  const _ret = {
    upload: [],
    download: [],
    stat: {
      total: 0,
      filled: 0,
      remain: 0,
    }
  };

  //nfh-console.log("masterId:", masterId);

  if (Array.isArray(docList) && masterId) {
    //nfh-console.log("docList.length", docList.length);

    for (const el of docList) {
      if (el) {
        _hasChecklist = (el.checklist && String(el.checklist) === "[object Object]" && Object.keys(el.checklist).length > 0);

        let data = {
          name: el.name,
          roleid: el.pivot.role_id,
          mandatory: el.pivot.mandatory,
          fulfilled: _hasChecklist,
          master_id: masterId,
          download_only: (_hasChecklist) ? el.checklist.download_only : el.download_only,
          checklist_masters_id: el.id,
          checklist_id: (_hasChecklist) ? el.checklist.id : "",
          media_name: (_hasChecklist) ? el.checklist.media_name : el.filename,
          media_url: (_hasChecklist) ? await getSecuredFile(el.checklist.media_url) : "",
          raw_url: (_hasChecklist) ? el.checklist.media_url : "",
        };

        if (isGuest) data.formid = masterId;
        else data.appid = masterId;

        if (el.download_only == true) {
          checklistDownloadData.push(data);
          //nfh-console.log('PUSHED checklistDownloadData:', data);
        }
        else {
          checklistData.push(data);
          //nfh-console.log('PUSHED checklistData:', data);

          if (data.mandatory) {
            _chklistStat.total++;
            _chklistStat.filled += _hasChecklist ? 1 : 0;
            _chklistStat.remain = _chklistStat.total - _chklistStat.filled;
          }
        }
      }
    }

    // this.$store.commit(SET_CHECKLIST_DOWNLOAD_DATA, checklistDownloadData);
    // console.log('checklistDownloadData:', checklistDownloadData);

    // this.$store.commit(SET_CHECKLIST_DATA, checklistData);
    // console.log('checklistData:', checklistData);

    // this.$store.commit(SET_CHECKLIST_STAT, _chklistStat);

    _ret.stat = _chklistStat;
    _ret.upload = checklistData;
    _ret.download = checklistDownloadData;
  }

  //nfh-console.log('OUT: generateChecklist');
  return _ret;
}


export const makeDataReadable = (responseData) => {
  //nfh-console.log(' IN: makeDataReadable', responseData);

  var _ret = responseData;

  if (isObject(responseData)) {
    if (responseData?.class == 1 && responseData?.data) {
      const data = JSON.parse(Buffer.from(responseData.data, 'base64').toString());
      //nfh-console.log('Parsed Data: ', data);
      responseData.data = data;
      responseData.class = 0;
      //nfh-console.log('Prepared responseData: ', responseData);
    }
    _ret = responseData;
  }
  else {
    //nfh-console.log('makeDataReadable: isObject(responseData): ', isObject(responseData));
  }

  //nfh-console.log('OUT: makeDataReadable', _ret);

  return _ret;
}


/**
 * @param {any} value
 * @return {boolean}
 */
export const isObject = (value) => {
  return (typeof value === 'object' && !Array.isArray(value) && value === Object(value));
}

export default {
  ord,
  isMapObject,
  sanitize_nric,
  extractInfoNRIC,
  convUpperCase,
  errorMessages,
  promptError,
  formatAddress,
  formatNumber,
  formatAmount,
  formatPercentage,
  emailFormatCheck,
  getSecuredFile,
  is_valid_nric,
  blankHeir,
  blankMember,
  blankUser_old,
  blankEmployment,
  blankDSR,
  calcDSR,
  extractPagingInfoOnly,
  verifyPagedData,
  generateChecklist,
  makeDataReadable,
};
