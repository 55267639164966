import axios from "axios";
import ApiService from "../api.service";
import JwtService from "../jwt.service";
import config from "@/core/services/store/config.module";

// action types
export const GET_NOTIFICATIONS = "getNotifications";
export const GET_NOTIFICATIONS_BY_PAGE = "getNotificationsByPage";
export const SEND_VERIFY_EMAIL = "sendVerifyEmail";
export const REQUEST_RESET_PASSWORD = "requestResetPassword";
// export const VERIFY_EMAIL = "verifyEmail";
// export const REDIRECTOR = "redirector";
export const NOTIFY_NEW_APPLICATION = "notifyNewApplication"
export const NOTIFY_NEW_ASSISTANCE_REQUEST = "notifyNewAssistanceRequest"

// mutation types
export const SET_TOKEN = "setToken";
export const SET_NOTIFICATIONS = "setNotifications";

const state = {
  notifications: {
    data: {
      data: {}
    }
  },
};

const getters = {
  notifications(state) {
    return state.notifications;
  }
};

const actions = {
  async [GET_NOTIFICATIONS](context, credentials) {
    // if (JwtService.getToken()) {
    //   ApiService.setHeader();
    //   return ApiService.get("/notifications");
    // }
    const apiRoute = "/notifications";
    var _ret = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      await ApiService.get(apiRoute).then((resp) => {
        const _res = resp?.data;

        if (_res.success) {
          //nfh-console.log(`[${apiRoute}] GET_NOTIFICATIONS <fetched>`, _res);
          context.commit(SET_NOTIFICATIONS, JSON.parse(Buffer.from(_res?.data, 'base64')));
          _ret = _res;
        }
        else {
          //nfh-console.log(`[${apiRoute}] GET_NOTIFICATIONS <FAILED>`, resp);
        }
      })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] GET_NOTIFICATIONS <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] GET_NOTIFICATIONS <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] GET_NOTIFICATIONS <getToken FAILED>`);
    }

    return _ret;
  },
  async [GET_NOTIFICATIONS_BY_PAGE](context, credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return axios.get(credentials);
    }
  },
  async [SEND_VERIFY_EMAIL](context, credentials) {
    if (credentials) {
      credentials.appid = (process.env.NODE_ENV === "production") ? config.state.appid_prod : config.state.appid_dev;

      if (JwtService.getToken()) {
        ApiService.setHeader();
        return await ApiService.post("/notify/verifyemail", credentials);
      }
    }
  },
  async [REQUEST_RESET_PASSWORD](state, credentials) {
    if (credentials) {
      // if (JwtService.getToken()) {
      //   ApiService.setHeader();
      //   return await ApiService.post("/notify/resetPassword", credentials);
      // }
      return await ApiService.post("/notify/resetPassword", credentials);
    }
  },

  // ============================================================
  // DEPRECATED: REPLACED BY REDIRECTOR
  // ============================================================
  // async [VERIFY_EMAIL](context, credentials) {
  //     if (credentials) {
  //         if (credentials.token) {
  //             ApiService.setHeaderByParam(credentials.token);
  //             return ApiService.post("/user/verifyEmail", credentials);
  //         }
  //     }
  // },
  // ============================================================

  // ============================================================
  // DEPRECATED: MOVED TO REDIRECTOR.MODULE.JS
  // ============================================================
  // async [REDIRECTOR](context, credentials) {
  //     if (credentials) {
  //         return ApiService.query("/redirect?" + new URLSearchParams(credentials).toString());
  //     }
  // },
  // ============================================================

  async [NOTIFY_NEW_APPLICATION](context, credentials) {
    if (credentials) {
      // xxconsole.log(credentials);

      if (JwtService.getToken()) {
        ApiService.setHeader();
        return ApiService.post("/apps/notify/newsub", credentials);
      }
    }
  },

  async [NOTIFY_NEW_ASSISTANCE_REQUEST](context, credentials) {
    if (credentials) {
      const apiRoute = "/apps/notify/newasst";
      var _ret = null;
      var _data = {};


      if (JwtService.getToken()) {
        ApiService.setHeader();
        // return ApiService.post(apiRoute, credentials);
        await ApiService.post(apiRoute, credentials)
          .then((resp) => {
            const _res = resp?.data;

            if (_res?.success) {
              //nfh-console.log(`[${apiRoute}] CREATE_APPLICATION <fetched>`, _res);

              let _readableResp = makeDataReadable(_res);
              //nfh-console.log(`[${apiRoute}] CREATE_APPLICATION makeDataReadable`, _readableResp);
              _ret = _readableResp;
            }
            else {
              _ret = _res;
              //nfh-console.log(`[${apiRoute}] CREATE_APPLICATION <FAILED>`, resp);
            }
          })
          .catch(err => {
            //nfh-console.log(`[${apiRoute}] CREATE_APPLICATION <CATCH-ERR>`, err);
          });

        if (!_ret) {
          //nfh-console.log(`[${apiRoute}] CREATE_APPLICATION <NULLED>`);
        }

        return _ret ?? {};
      }
    }
  },

}

const mutations = {
  [SET_TOKEN](state, token) {
    JwtService.saveToken(token);
    // xxconsole.log(JwtService.getToken());
  },

  [SET_NOTIFICATIONS](state, notifications) {
    state.notifications = notifications;
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
}