import ApiService from "../api.service";
import JwtService from "../jwt.service";

// action types
export const CREATE_DSR = "createDSR";
export const GET_DSR = "getDSR";

// mutation types


const state = {

}

const getters = {

}

const actions = {
    async [CREATE_DSR](context, credentials) {
        if (credentials) {
            if (JwtService.getToken()) {
                ApiService.setHeader();
                return await ApiService.post("/apps/dsr/create", credentials);
            }
        }
    },

    async [GET_DSR](context, credentials) {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            return await ApiService.post(`/apps/${credentials}/dsr/get`);
        }
    },

}

const mutations = {

}

export default {
    state,
    getters,
    actions,
    mutations,
}