<template>
  <div
    v-if="displayAlert"
  >
    <v-alert
      v-model="isShow"
      class="alert"
      border="left"
      :type="panelType"
      :outlined="outlined"
      dismissable
      prominent
      dense
    >
      <v-row
        align="center"
      >
        <v-col
          class="grow"
        >
          <div
            class="text-h6 alert-text"
            :class="customClass"
            v-html="alertMessage"
          ></div>
              <!-- {{ alertMessage }} -->
          <div
            v-if="alertHtml"
            v-html="alertHtml"
            class="text-h6 alert-text"
            :class="customClass"
          ></div>
        </v-col>
        <v-col
          class="shrink mr-8"
        >
          <v-btn
            v-if="isButton1Displayable"
            class="m-3"
            elevation="4"
            :color="buttonColor"
            @click="action1()"
            dark
            block
          >
            {{ button1Caption | buttonNonBreakCaption }}
          </v-btn>
          <v-btn
            v-if="isButton2Displayable"
            class="m-3"
            elevation="4"
            :color="buttonColor"
            @click="action2()"
            dark
            block
          >
            {{ button2Caption | buttonNonBreakCaption }}
          </v-btn>
          <v-btn
            v-if="isButton3Displayable"
            class="m-3"
            elevation="4"
            :color="buttonColor"
            @click="action3()"
            dark
            block
          >
            {{ button3Caption | buttonNonBreakCaption }}
          </v-btn>
        </v-col>
        <v-col
          v-if="dismissable"
          class="shrink"
        >
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            @click="close()"
          >
            <b-icon
              icon="x"
              scale="2"
              variant="dark"
            ></b-icon>
          </button>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    alertMessage: {
      type: String,
      default: "",
    },

    alertHtml: {
      type: String,
      default: "",
    },

    alertType: {
      type: String,
      default: ""
    },

    button1Caption: {
      type: String,
      default: "",
    },

    button2Caption: {
      type: String,
      default: "",
    },

    button3Caption: {
      type: String,
      default: "",
    },

    dismissable: {
      type: Boolean,
      default: false,
    },

    reappearMinute: {
      type: Number,
      default: 10,
    },

    outlined: {
      type: Boolean,
      default: false,
    },

    bold: {
      type: Boolean,
      default: true,
    },

  },

  watch: {
  },

  data() {
    return {
      isShow: true,
      customClass: "",
    }
  },

  computed: {
    displayAlert: {
      get() {
        return !!(this.value && this.isShow);
      },

      set(newValue) {
        this.$emit("input", newValue);
      },

    },

    panelType() {
      const _t = String(this?.alertType).toLowerCase().trim();
      //nfh-console.log('alertType: ', _t, typeof _t);
      return Array("success", "warning", "error", "info").includes(_t) ? _t : "info";
    },

    buttonColor() {
      return (!!this.outlined) ? this.panelType : "";
    },

    isAlertDisplayable() {
      return !!!(this.alertMessage.trim() == "");
    },

    isButton1Displayable() {
      return !!!(this.button1Caption.trim() == "");
    },

    isButton2Displayable() {
      return !!!(this.button2Caption.trim() == "");
    },

    isButton3Displayable() {
      return !!!(this.button3Caption.trim() == "");
    },

  },

  methods: {
    action1() {
      this.$emit("onAction1");
    },

    action2() {
      this.$emit("onAction2");
    },

    action3() {
      this.$emit("onAction3");
    },

    close() {
      this.isShow = false;
      setTimeout(() => { this.isShow = true }, (this.reappearMinute ?? 10) * 60 * 1000);
    },

  },

  created() {
  },

  mounted() {
    this.class = (this.bold) ? "font-weight-bold" : this.class;
  },

};
</script>

<style scoped>
</style>