export const START_TIMER = "startTimer";
export const RESET_TIMER = "resetTimer";

const state = {
  ticker: null,
  max_wait: 180,
  secs: 180,
  counter: false,
};

const getters = {
  timer(state) {
    return state.secs;
  },
};

const actions = {
  async [START_TIMER]() {
    state.counter = true;
    try {
      while (state.secs > 0 && state.counter) {
        await new Promise((resolve) => state.ticker = setTimeout(resolve, 1000));
        if (state.counter) state.secs--;
      }
      clearTimeout(state.ticker);
    } catch (err) {}
    state.counter = false;
  },
  [RESET_TIMER]() {
    clearTimeout(state.ticker);
    state.secs = state.max_wait;
    state.counter = false;
  },
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters,
};
