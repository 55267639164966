<template>
  <v-row
    justify="center"
  >
    <v-dialog
      v-model="displayConfirmationDialog"
      max-width="400"
      persistent
    >
      <v-card
        class="pa-6"
      >
        <v-row
          style="text-align: justify;"
        >
          <v-col
            cols="12"
          >
            <v-card-title
              class="px-0 py-0"
            >
              <p
                class="font-weight-bold"
              >
                {{ title }}
              </p>
            </v-card-title>
            <v-card-text
              class="px-0 py-0"
            >
              <p
                class="body-1"
                style="white-space: pre-line;"
                v-html="content"
              ></p>
            <!-- {{ content }} -->
            </v-card-text>
          </v-col>

          <v-col
            class="d-flex flex-wrap justify-content-end align-items-center"
            cols="12"
          >
            <v-btn
              v-if="showBackButton !== false"
              text
              @click="closeConfirmationDialog()"
            >
              <span
                class="font-weight-bold"
              >
                {{ backButton }}
              </span>
            </v-btn>

            <v-btn
              v-if="showProceedButton !== false"
              class="ml-4"
              color="primary"
              :loading="isLoading"
              @click="onProceed()"
            >
              <span
                class="font-weight-bold"
              >
                {{ proceedButton }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: "Pengesahan",
    },

    proceedButton: {
      type: String,
      default: "Teruskan",
    },

    backButton: {
      type: String,
      default: "Batal",
    },

    content: {
      type: String,
      default: "",
    },

    showProceedButton: {
      type: Boolean,
      default: false,
    },

    showBackButton: {
      type: Boolean,
      default: false,
    },

    delayOnProceed: {
      type: Boolean,
      default: true,
    },

    proceedDelaySec: {
      type: Number,
      default: 2,
    },

  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    displayConfirmationDialog: {
      get() {
        return this.value;
      },

      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },

  methods: {
    closeConfirmationDialog() {
      this.displayConfirmationDialog = false;
      this.$emit("onCancel");
    },

    onProceed() {
      this.isLoading = true;
      setTimeout(() => {
        this.$emit("onProceed");
        this.isLoading = false;
      }, ((this.delayOnProceed) ? this.proceedDelaySec * 1000 : 0));
    },
  },

  mounted() {
  },
};
</script>

<style scoped></style>
