import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import firebaseMessaging from './firebase';
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import MockService from "@/core/mock/mock.service";
import {
  VERIFY_AUTH,
} from "@/core/services/store/auth.module";

import {
  RESET_LAYOUT_CONFIG,
} from "@/core/services/store/config.module";

// import {
//   GET_LOOKUPS,
// } from "@/core/services/store/lookup.module";

import {
  formatNumber,
  formatAmount,
  makeDataReadable,
} from "@/core/services/helper.service";

Vue.config.productionTip = false;
// Vue.config.devtools = true;
// Vue.config.debug = true;


// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";
// import moment from 'moment';
import dayjs from "dayjs";
import dayjs_msmy from "dayjs/esm/locale/ms.js";
// Vee Validate component
import { ValidationProvider } from "vee-validate";
// Vue Mask
import VueMask from "v-mask";
// Vue Signature Pad
import VueSignaturePad from "vue-signature-pad";
// Vue Money
import VuetifyMoney from "vuetify-money";
// Vue Number Format
import VueNumberFormat from "vue-number-format";
// Vue Phone Number Input
import VueTelInputVuetify from "vue-tel-input-vuetify";
// Vue Sweet Alert2
import VueSweetalert2 from "vue-sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';
// VueUploadComponent
import VueUploadComponent from "vue-upload-component";
// VueNotification
import Notifications from 'vue-notification'

import InfoMsgDialog from "@/view/pages/widgets/MessageDialog.vue";
import ErrorMsgDialog from "@/view/pages/widgets/MessageDialog.vue";
import ConfirmationDialog from "@/view/pages/widgets/ConfirmationDialog.vue";
import AlertActionable from "@/view/pages/widgets/AlertActionable.vue";
import Forwarder from "@/view/pages/Forwarder.vue";

// API service init
ApiService.init();

dayjs.locale(dayjs_msmy, null, true);

// Remove this to disable mock API
// MockService.init();
// debugger;
// store.dispatch(GET_LOOKUPS);

// router.beforeEach((to, from, next) => {
//   // Ensure we checked auth before each page load.
//   Promise.all([store.dispatch(VERIFY_AUTH)])
//     .then(next)
//     .catch(err => {
//       // xxconsole.log(err);
//       // debugger;
//       this.$router.push("/login");
//     });
//
//   // reset config to initial state
//   store.dispatch(RESET_LAYOUT_CONFIG);
//
//   // Scroll page to top on every route change
//   setTimeout(() => {
//     window.scrollTo(0, 0);
//   }, 100);
// });

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  //nfh-console.log('router.beforeEach to', to);
  //nfh-console.log('router.beforeEach from', from);
  //nfh-console.log('router.beforeEach next', next);

  // switch (this.$route.query.act) {
  //   case "verify_email":
  //     console.log('REDIRECTOR Router.Replace: VERIFYEMAIL', this.$route.query)
  //     this.$router.replace({
  //       name: "VerifyEmail",
  //       query: this.$route.query,
  //     });
  //     break;
  if (to.fullPath == "/login") {
    next();
  }
  else if (to.name == "Redirect" && to.query.act == "verify_email") {
    next();
  }
  else if (to.name == "VerifyEmail" && to.query.act == "verify_email") {
    next();
  }
  else {
    Promise.all([store.dispatch(VERIFY_AUTH)])
      .then((resp) => {
        // console.log('ROUTER.BEFOREEACH:', resp);
        // console.log('to', to);
        // console.log('from', from);
        // console.log('resp[0].message', resp[0].message);
        (resp[0].message !== 'ok') ? next("/login") : next();
      })
      .catch(err => {
        // console.log('ROUTER.BEFOREEACH - ERROR:', err);
        next("/login");
      });
  }

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

Vue.prototype.$messaging = firebaseMessaging;

Vue.component('Forwarder', Forwarder);
Vue.component('file-upload', VueUploadComponent);
Vue.component('InfoMsgDialog', InfoMsgDialog);
Vue.component('ErrorMsgDialog', ErrorMsgDialog);
Vue.component('AlertActionable', AlertActionable);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component('ConfirmationDialog', ConfirmationDialog);

Vue.use(VueMask);
Vue.use(VueSignaturePad);
Vue.use(VuetifyMoney);
Vue.use(VueSweetalert2);
Vue.use(VueNumberFormat, {
  prefix: "",
  decimal: "",
  thounsand: "",
  precision: 0,
  acceptNegatve: false,
});

Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.use(Notifications);

Vue.filter('upperCase', function(value) {
  if (value) {
    return value.toUpperCase()
  }
});

Vue.filter('buttonNonBreakCaption', function(value) {
  if (value) {
    return value.toUpperCase().replace(" ", "\u00A0")
  }
});

Vue.filter('formatDate', function(value) {
  if (value) {
    return dayjs(value).format('DD/MM/YYYY')
  }
});

Vue.filter('formatDateTime', function(value) {
  if (value) {
    return dayjs(value).format('DD/MM/YYYY, hh:mm:ss a');
  }
})

Vue.filter('formatDateWordy', function(value) {
  if (value) {
    return dayjs(value).locale('ms').format('D MMM YYYY');
  }
})

Vue.filter('formatDateDayMonth', function(value) {
  if (value) {
    return dayjs(value).locale('ms').format('D MMM \'YY');
  }
})

Vue.filter('formatDateLog', function(value) {
  if (value) {
    return dayjs(value).locale('ms').format('D MMM \'YY h:mm');
  }
})

Vue.filter('formatTimeShort', function(value) {
  if (value) {
    return dayjs(value).locale('ms').format('h:mm');
  }
})

Vue.filter('formatTime', function(value) {
  if (value) {
    return dayjs(value).locale('ms').format('hh:mm:ss a');
  }
})

Vue.filter('formatSize', function (size) {
  if (size > 1024 * 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB'
  } else if (size > 1024 * 1024 * 1024) {
    return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB'
  } else if (size > 1024 * 1024) {
    return (size / 1024 / 1024).toFixed(2) + ' MB'
  } else if (size > 1024) {
    return (size / 1024).toFixed(2) + ' KB'
  }
  return size.toString() + ' B'
})

Vue.filter('fNumber', function (value) {
  value ??= 0.0;
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
})

Vue.filter('fAmount', function (value) {
  value ??= 0.0;
  return 'RM ' + formatNumber(value)
})

Vue.filter('fPercentage', function (value) {
  value ??= 0.0;
  return `${formatNumber(value.toFixed(2))} %`;
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
