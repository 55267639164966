import ApiService from "../api.service";
import JwtService from "../jwt.service";
import {
  makeDataReadable,
} from "@/core/services/helper.service";

// actions
export const CHECK_TELNO_AVAILABILITY = "checkTelnoAvailability";
export const CHECK_EMAIL_AVAILABILITY = "checkEmailAvailability";
export const FETCH_RAW_URL            = "fetchRawUrl";

const state = {

}

const getters = {

}

const actions = {
  async [CHECK_TELNO_AVAILABILITY](context, credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return await ApiService.post("/chk/telno/availability", credentials);
    }
  },

  async [CHECK_EMAIL_AVAILABILITY](context, credentials) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return await ApiService.post("/chk/email/availability", credentials);
    }
  },

  async [FETCH_RAW_URL](context, credentials) {
    const apiRoute = credentials;
    var _ret = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      await axios.get(apiRoute)
        .then((resp) => {
          // const _res = resp;
          const _res = makeDataReadable(resp?.data);
          _res.status = resp?.status;

          if (_res.success) {
            //nfh-console.log(`[${apiRoute}] FETCH_RAW_URL <fetched>`, _res);
            // _res.data.data = JSON.parse(Buffer.from(_res.data.data, 'base64'));
          }
          else {
            //nfh-console.log(`[${apiRoute}] FETCH_RAW_URL <FAILED>`, resp);
          }

          _ret = _res;
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] FETCH_RAW_URL <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] FETCH_RAW_URL <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] FETCH_RAW_URL <getToken FAILED>`);
    }

    return _ret;
  },
}

const mutations = {

}

export default {
    state,
    actions,
    mutations,
    getters,
};