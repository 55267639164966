import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH              = "verifyAuth";
export const INITSTEP                 = "initstep";
export const USERDBCHECKING           = "userDBChecking";
export const USERLXCHECKING           = "userLXChecking";
export const GETMEMBERSHIPANDREGISTER = "getMembershipAndRegister";
export const LOGIN                    = "login";
export const LOGIN_WITH_OTP           = "loginWithOTP";
export const LOGOUT                   = "logout";
export const CLEAR_LOGIN              = "clearLogin";
export const REGISTER                 = "register";
export const REGISTER_EXISTING_USER   = "registerExistingUser"
export const UPDATE_PASSWORD          = "updateUser";

// mutation types
export const PURGE_AUTH               = "logOut";
export const SET_AUTH                 = "setAuth";
export const SET_OTP_AUTH             = "setOTPAuth";
export const SET_RESPONSE             = "setRespose";
export const SET_PASSWORD             = "setPassword";
export const SET_ERROR                = "setError";

const state = {
  response: {
    success: false,
    message: "",
    data: {}
  },

  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  isAuthenticated(state) {
    return state.isAuthenticated;
  },

  response(state) {
    return state.response;
  }
};

const actions = {
  async [INITSTEP](context, credentials) {
    return await ApiService.post("/initstep", credentials);
  },

  async [USERDBCHECKING](context, credentials) {
    return await ApiService.post("/userDBChecking", credentials);
  },

  async [USERLXCHECKING](context, credentials) {
    return await ApiService.post("/userLXChecking", credentials);
  },

  async [GETMEMBERSHIPANDREGISTER](context, credentials) {
  },

  async [LOGIN](context, credentials) {
    return await ApiService.post("/auth/verifypwd", credentials);
  },

  async [LOGIN_WITH_OTP](context, credentials) {
    return await ApiService.post("/loginWithOTP", credentials);
  },

  async [LOGOUT](context) {
    const apiRoute = `/logout`;
    var _ret       = null;

    if (JwtService.getToken()) {
      ApiService.setHeader();

      await ApiService.post(apiRoute)
        .then((resp) => {
          const data = resp?.data;

          if (data.success) {
            //nfh-console.log(`[${apiRoute}] LOGOUT <fetched>`, data);
            _ret = data;
          }
          else {
            //nfh-console.log(`[${apiRoute}] LOGOUT <FAILED>`, resp);
          }

          context.commit(PURGE_AUTH);
        })
        .catch(err => {
          //nfh-console.log(`[${apiRoute}] LOGOUT <CATCH-ERR>`, err);
        });

      if (!_ret) {
        //nfh-console.log(`[${apiRoute}] LOGOUT <NULLED>`);
      }
    }
    else {
      //nfh-console.log(`[${apiRoute}] LOGOUT <getToken FAILED>`);
    }

    return _ret;
  },

  async [CLEAR_LOGIN](context) {
    context.commit(PURGE_AUTH);
  },

  [REGISTER](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("/register", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);

          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });

  },

  [REGISTER_EXISTING_USER](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("/registerExistingUser", credentials)
        .then(({ data }) => {
          //console.log(data);
          context.commit(SET_AUTH, data);

          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  // async [VERIFY_AUTH](context) {
  //   if (await JwtService.getToken()) {
  //     ApiService.setHeader();
  //     await ApiService.get("/validate-token")
  //       .then(({ data }) => {
  //         return data;
  //       })
  //       .catch(( err ) => {
  //         context.commit(PURGE_AUTH);
  //         context.commit(SET_ERROR, err);
  //         Promise.reject(err);
  //       });
  //   } else {
  //     context.commit(PURGE_AUTH);
  //     return false;
  //   }
  // },

  async [VERIFY_AUTH](context) {
    return new Promise(async (resolve, reject) => {
      if (JwtService.getToken()) {
        ApiService.setHeader();

        await ApiService.get("/validate-token")
          .then(async ( {data} ) => {
            //nfh-console.log('VERIFY_AUTH: ', data);

            resolve(data);
          })
          .catch(( err ) => {
            context.commit(PURGE_AUTH);
            context.commit(SET_ERROR, err);

            resolve(err);
          });
      }
      else {
        //nfh-console.log('VERIFY_AUTH: FALSE');
        context.commit(PURGE_AUTH);

        resolve( { message: 'NOTOKEN'} );
      }
    })
  },

  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },

};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_AUTH](state, auth) {
    state.isAuthenticated = true;
    state.response        = auth;

    JwtService.saveToken(auth.token);
  },

  [SET_OTP_AUTH](state, otpAuth) {
    state.isAuthenticated = otpAuth.success;
    state.response        = otpAuth;

    JwtService.saveToken(otpAuth.token);
  },

  [SET_RESPONSE](state, response) {
    state.response.success = response.success;
    state.response.message = response.message;
    state.response.data    = response.data;
  },

  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },

  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user            = {};
    state.errors          = {};

    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
