import ApiService from "@/core/services/api.service";
import {
  makeDataReadable,
} from "../helper.service";

// action types
export const GET_TESTMODE           = "configsTestMode";
export const GET_ONLYACCEPTREALNRIC = "configsOnlyAcceptRealNRIC";

// mutation types
export const SET_TESTMODE           = "setTestmode";
export const SET_ONLYACCEPTREALNRIC = "setOnlyAcceptRealNRIC";


const state = {
  testMode: true,
  onlyAcceptRealNRIC: false,
  stepsBypassable: false,
  errorTitles: {
  },
};

const getters = {
  testMode(state) {
    return state.testMode;
  },

  onlyAcceptRealNRIC(state) {
    return state.onlyAcceptRealNRIC;
  },

  stepsBypassable(state) {
    return state.stepsBypassable;
  },

  errorTitles(state) {
    return state.errorTitles;
  },
}

const actions = {
  async [GET_TESTMODE](context) {
    ApiService.get("/configs/c_tm")
      .then(( {data} ) => {
        const _res = makeDataReadable(data);

        //nfh-console.log('GET_TESTMODE: ', _res);
        context.commit(SET_TESTMODE, !!_res?.data ?? false);
      });
  },

  async [GET_ONLYACCEPTREALNRIC](context) {
    ApiService.get("/configs/c_rico")
      .then(( {data} ) => {
        const _res = makeDataReadable(data);

        //nfh-console.log('GET_ONLYACCEPTREALNRIC: ', _res);
        context.commit(SET_ONLYACCEPTREALNRIC, !!_res?.data ?? true);
      });
  },

}

const mutations = {
  [SET_TESTMODE](state, mode) {
    state.testMode = !!mode;
  },

  [SET_ONLYACCEPTREALNRIC](state, value) {
    state.onlyAcceptRealNRIC = !!value;
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}