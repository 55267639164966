var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.displayAlert
    ? _c(
        "div",
        [
          _c(
            "v-alert",
            {
              staticClass: "alert",
              attrs: {
                border: "left",
                type: _vm.panelType,
                outlined: _vm.outlined,
                dismissable: "",
                prominent: "",
                dense: "",
              },
              model: {
                value: _vm.isShow,
                callback: function ($$v) {
                  _vm.isShow = $$v
                },
                expression: "isShow",
              },
            },
            [
              _c(
                "v-row",
                { attrs: { align: "center" } },
                [
                  _c("v-col", { staticClass: "grow" }, [
                    _c("div", {
                      staticClass: "text-h6 alert-text",
                      class: _vm.customClass,
                      domProps: { innerHTML: _vm._s(_vm.alertMessage) },
                    }),
                    _vm.alertHtml
                      ? _c("div", {
                          staticClass: "text-h6 alert-text",
                          class: _vm.customClass,
                          domProps: { innerHTML: _vm._s(_vm.alertHtml) },
                        })
                      : _vm._e(),
                  ]),
                  _c(
                    "v-col",
                    { staticClass: "shrink mr-8" },
                    [
                      _vm.isButton1Displayable
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "m-3",
                              attrs: {
                                elevation: "4",
                                color: _vm.buttonColor,
                                dark: "",
                                block: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.action1()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("buttonNonBreakCaption")(
                                      _vm.button1Caption
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.isButton2Displayable
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "m-3",
                              attrs: {
                                elevation: "4",
                                color: _vm.buttonColor,
                                dark: "",
                                block: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.action2()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("buttonNonBreakCaption")(
                                      _vm.button2Caption
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.isButton3Displayable
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "m-3",
                              attrs: {
                                elevation: "4",
                                color: _vm.buttonColor,
                                dark: "",
                                block: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.action3()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("buttonNonBreakCaption")(
                                      _vm.button3Caption
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.dismissable
                    ? _c("v-col", { staticClass: "shrink" }, [
                        _c(
                          "button",
                          {
                            staticClass: "close",
                            attrs: {
                              type: "button",
                              "data-dismiss": "alert",
                              "aria-label": "Close",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.close()
                              },
                            },
                          },
                          [
                            _c("b-icon", {
                              attrs: { icon: "x", scale: "2", variant: "dark" },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }