import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import calculator from "./calculator.module";
import breadcrumbs from "./breadcrumbs.module";
import user from "./user.module";
import profile from "./profile.module";
import pdf from "./file.module";
import timer from "./timer.module";
import application from "./application.module";
import approvedapplication from "./approvedapplication.module";
import applogs from "./applogs.module";
import form from "./form.module";
import otp from "./otp.module";
import mediaupload from "./mediaupload.module";
import lookup from "./lookup.module";
import notification from "./notification.module";
import redirector from "./redirector.module";
import dsr from "./dsr.module";
import helper from "./helper.module";
import setting from "./setting.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    calculator,
    breadcrumbs,
    user,
    profile,
    pdf,
    timer,
    application,
    approvedapplication,
    applogs,
    form,
    otp,
    mediaupload,
    lookup,
    notification,
    redirector,
    dsr,
    helper,
    setting,
  }
});
