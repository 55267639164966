var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "center" } },
    [
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400", persistent: "" },
          model: {
            value: _vm.displayConfirmationDialog,
            callback: function ($$v) {
              _vm.displayConfirmationDialog = $$v
            },
            expression: "displayConfirmationDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pa-6" },
            [
              _c(
                "v-row",
                { staticStyle: { "text-align": "justify" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-card-title", { staticClass: "px-0 py-0" }, [
                        _c("p", { staticClass: "font-weight-bold" }, [
                          _vm._v(" " + _vm._s(_vm.title) + " "),
                        ]),
                      ]),
                      _c("v-card-text", { staticClass: "px-0 py-0" }, [
                        _c("p", {
                          staticClass: "body-1",
                          staticStyle: { "white-space": "pre-line" },
                          domProps: { innerHTML: _vm._s(_vm.content) },
                        }),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "d-flex flex-wrap justify-content-end align-items-center",
                      attrs: { cols: "12" },
                    },
                    [
                      _vm.showBackButton !== false
                        ? _c(
                            "v-btn",
                            {
                              attrs: { text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.closeConfirmationDialog()
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(" " + _vm._s(_vm.backButton) + " "),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _vm.showProceedButton !== false
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                color: "primary",
                                loading: _vm.isLoading,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onProceed()
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "font-weight-bold" }, [
                                _vm._v(" " + _vm._s(_vm.proceedButton) + " "),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }