<template>
  <div>
    <v-overlay
      :value="overlay"
    >
      <v-progress-circular
        size="64"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
</div>
</template>

<script>
import {
  mapGetters,
} from "vuex";

export default {
  name: "Forwarder",

  components: {
  },

  props: {
    next: {
      type: String,
      default: 'dashboard',
    },
  },

  computed: {
    ...mapGetters([
      "layoutConfig",
    ]),
  },

  data() {
    return {
      overlay: false,
    }
  },

  methods: {
    onProceed(goto) {
      switch (String(goto).toLowerCase()) {
        case "dashboard":
          //nfh-console.log("FORWARDER dashboard: ", goto);
          this.$router.push("/dashboard");
          break;

        default:
          //nfh-console.log("FORWARDER ", goto);
          this.$router.push("/form");
          // this.$router.replace({
          //   path: goto,
          // });
      }
    },
  },

  async mounted() {
    //nfh-console.log(" IN:MOUNTED")
    this.onProceed('/' + this.next);
    //nfh-console.log("OUT:MOUNTED")
  },
}
</script>

<style>
</style>