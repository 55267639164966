// Import the functions you need from the SDKs you need
import firebase from "firebase/app";
import 'firebase/firebase-messaging'
// import { firebaseConfig } from './firebase/firebase-config';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  // ========================================================
  // TODO: USE FOLLOWING CONFIG IN PRODUCTION
  // ========================================================
  apiKey: "AIzaSyCnMTZTgMSKbxx4_qbdEWfzuZwjpM1fTE0",
  authDomain: "mye-wawasan.firebaseapp.com",
  databaseURL: "https://mye-wawasan-default-rtdb.firebaseio.com",
  projectId: "mye-wawasan",
  storageBucket: "mye-wawasan.appspot.com",
  messagingSenderId: "789684546080",
  appId: "1:789684546080:web:d355aaae4c87feb4c5ea45",
  measurementId: "G-YNX88YBLSJ"
  // ========================================================
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

export default firebase.messaging();